<script>
	import { onDestroy, onMount } from 'svelte';
	import { notification, showSpinner } from '$src/stores.js';
	import { logPlausibleEvent } from '$utils/plausible.js';
	import { handleConsentResponse } from '$utils/consent.js';
	import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte';
	import Notification from '$lib/Notification.svelte';
	import { IS_PROD } from '$src/constants.js';

	let authorizationCancelled = false;
	let remoteAuthDone = false;
	let evtSource;
	let app = {};

	onMount(async () => {
		$showSpinner = true;

		notification.show('QR Code was scanned', 'success');

		evtSource = new EventSource('/api/v1/login/qrcode/remote');
		evtSource.addEventListener('response', (event) => {
			remoteAuthDone = true;
			evtSource.close();
			try {
				const json = JSON.parse(event.data);
				if (json?.params?.error && json?.params?.error === 'access_denied') {
					notification.show('Authorization was cancelled<br/>on the other device', 'error');
				} else {
					notification.show('Authorization is complete', 'success');
				}
				setTimeout(() => {
					handleConsentResponse(json);
					return;
				}, 500);
			} catch (err) {
				console.error(err);
			}
		});

		evtSource.addEventListener('keep-alive', (event) => {
			if (!IS_PROD) {
				console.log('keep-alive: ' + event.data);
			}
		});

		try {
			app = JSON.parse(sessionStorage.app);
		} catch (err) {
			console.error(err);
		}

		logPlausibleEvent({ u: '/remote' });

		$showSpinner = false;
	});

	onDestroy(() => {
		if (evtSource) {
			evtSource.close();
		}
		notification.clear();
	});

	async function cancelConsent() {
		sessionStorage.removeItem('az_release_funnel');
		try {
			await fetch('/api/v1/login/qrcode', {
				method: 'DELETE'
			});
			evtSource.close();
			notification.show('Authorization was cancelled', 'error');
			authorizationCancelled = true;
			const response = {
				uri: app.redirect_uri,
				response_mode: app.response_mode,
				params: {
					error: 'access_denied'
				}
			};
			setTimeout(() => {
				handleConsentResponse(response);
				return;
			}, 500);
		} catch (err) {
			console.error(err);
		}
	}
</script>

{#if $notification.text}
	<Notification />
{/if}

<main class="flex-1 px-3 md:px-0 overflow-y-auto pb-16">
	<div class="mt-8">
		<div class="max-w-2xl mx-auto px-3 text-center">
			{#if remoteAuthDone || authorizationCancelled}
				<SpinnerIcon css="h-8 w-8 text-charcoal dark:text-[#d4d4d4] mx-auto mb-8" />
				<h2 class="text-xl">Redirecting you to<br />{app.name}</h2>
			{:else if !remoteAuthDone && !authorizationCancelled}
				<h1 class="text-xl text-center">Waiting for remote<br />authorization</h1>
				<SpinnerIcon css="h-8 w-8 text-charcoal dark:text-[#d4d4d4] mx-auto my-8" />
				<button class="hover:underline focus:underline block mx-auto" on:click={cancelConsent}
					>Cancel</button
				>
			{/if}
		</div>
	</div>
</main>
