import QRCode from 'qrcode';

const generateQR = async (text) => {
	try {
		return await QRCode.toString(text, {
			type: 'svg',
			color: {
				light: '#0000' // Transparent background
			}
		});
	} catch (err) {
		console.error(err);
	}
};

export { generateQR };
