<script>
	import { onMount, onDestroy } from 'svelte';
	import Router, { replace as replaceRoute } from 'svelte-spa-router';
	import routes from './authorize/index.js';
	import {
		data,
		notification,
		showSpinner,
		countdown,
		showUpgradePreferredModal,
		mergeAccounts
	} from '$src/stores.js';
	import { _, locale, isLoading } from 'svelte-i18n';
	import SessionTimerModal from '$lib/modal/SessionTimerModal.svelte';
	import { SESSION_EXPIRE_SHOW_MODAL_MS } from '$src/constants.js';
	import MergeModal from '$lib/modal/MergeModal.svelte';
	import { logout, getConsent, postClientError } from '$utils/api.js';
	import UpgradePreferredModal from '$lib/modal/UpgradePreferredModal.svelte';

	onMount(() => {
		//Remove load spinner in HTML page
		document.getElementById('load-spinner')?.remove();
		//Show Svelte spinner
		$showSpinner = true;

		//https://stackoverflow.com/a/75961014/9747630
		window.addEventListener('unhandledrejection', postClientError);
		window.addEventListener('error', postClientError);
	});

	onDestroy(() => {
		window.removeEventListener('unhandledrejection', postClientError);
		window.removeEventListener('error', postClientError);
	});

	let showSessionExpiredModal = false;
	$: if ($countdown <= SESSION_EXPIRE_SHOW_MODAL_MS / 1000) {
		showSessionExpiredModal = true;
		if ($countdown <= 0) {
			countdown.clear();
			logoutUser().finally(() => (showSessionExpiredModal = false));
		}
	}

	async function logoutUser() {
		try {
			await logout({ clearSession: false });
			$data = await getConsent(sessionStorage.authorize_query_params);
		} catch (err) {
			console.error(err);
		} finally {
			replaceRoute('/login');
			notification.show($_('You have been logged out'), 'error');
		}
	}

	$: {
		if ($locale && $locale.startsWith('ar')) {
			document.dir = 'rtl';
		} else {
			document.dir = 'ltr';
		}
	}

	// $: console.info('Session expires in', $countdown);
</script>

{#if (!$locale || $isLoading || $showSpinner) && !document.getElementById('load-spinner')}
	<div class="spinner" />
{/if}

{#if $locale && !$isLoading}
	{#if $mergeAccounts}
		<MergeModal />
	{/if}

	{#if $showUpgradePreferredModal}
		<UpgradePreferredModal />
	{/if}

	{#if showSessionExpiredModal}
		<SessionTimerModal on:close={() => (showSessionExpiredModal = false)} />
	{/if}

	<div class="px-outer max-w-[856px] mx-auto">
		<Router {routes} />
	</div>
{/if}
