<script>
	import { _ } from 'svelte-i18n';
	import { slide } from 'svelte/transition';
	import { data } from '$src/stores.js';
	import { dedupe } from '$utils/common';
	import Dropdown from '$lib/Dropdown.svelte';
	import VerifyEmail from '$lib/VerifyEmail.svelte';
	import { DEFAULT_MANAGED_LOGO } from '$src/constants.js';
	import LoginProvider from '$lib/LoginProvider.svelte';
	import logins from '$svr/providers/logins.json';
	import { getConsent } from '$utils/api.js';

	export let selected = '';
	export let expanded = false;
	export let emailOTPState = false;

	export let handleDropdown = () => {};
	export let continueWithProvider = () => {};

	let addEmailDropdown = false;

	async function verifyEmailSuccess() {
		try {
			const res = await getConsent();
			if (!res.merge) {
				$data.release.emails = res.release.emails;
				$data.release.accounts = res.release.accounts;
				if ($data.release.existing_usernames) {
					$data.release.existing_usernames = res.release.existing_usernames;
				}
				selected = $data.release.emails[0];
			}
			expanded = false;
			addEmailDropdown = false;
		} catch (err) {
			console.error(err);
		}
	}
</script>

<div data-test="email-container" class="space-y-1">
	<label for="email" class="text-sm font-medium">
		{$_('Verified Email Address')}
	</label>
	{#if $data?.release?.managed?.email}
		{@const meta = $data?.release?.managed?._meta}
		<div
			class="px-4 overflow-hidden block h-12 flex items-center rounded-md bg-charcoal bg-opacity-10 dark:bg-opacity-100"
		>
			<span class="managed-rounded-square-sm">
				<img
					src={meta.logo || DEFAULT_MANAGED_LOGO}
					alt={meta.domain || ''}
					class="w-4.5 max-h-[18px] object-contain"
				/>
			</span>
			<span class="ml-4">{selected}</span>
		</div>
	{:else if $data?.release?.update_scope}
		<div class="box-shadow space-y-2 p-2 rounded-md mt-2">
			<div data-test="emails-container" class="relative flex items-center">
				<div class="w-full">
					<ul class="flex flex-col items-start space-y-2 mt-1">
						{#each dedupe($data?.release?.emails) as email, index (email)}
							<li data-test="email-{index}" class="flex items-center w-full">
								<input
									id="email-{email}"
									name="email"
									type="radio"
									bind:group={selected}
									value={email}
									class="w-4 h-4 rounded-full form-radio text-charcoal dark:text-[#808080]"
								/>
								<label for="email-{email}" class="ml-2.5 inline-flex items-center truncate w-full">
									<span class="truncate">
										{email}
									</span>
								</label>
							</li>
						{/each}
					</ul>
				</div>
			</div>

			<div class="relative">
				<Dropdown
					hasBackground={false}
					dataTest="add-provider-btn"
					ariaLabel="Add another email"
					expanded={addEmailDropdown}
					on:click={() => (addEmailDropdown = !addEmailDropdown)}
				>
					<div class="h-12 flex items-center text-left px-4" aria-hidden="true">
						{$_('Add another email')}
					</div>

					{#if addEmailDropdown}
						<ul class="space-y-2 p-2" transition:slide|local>
							<div class="pb-2">
								<VerifyEmail
									on:otp={() => (emailOTPState = true)}
									on:success={verifyEmailSuccess}
								/>
							</div>
							{#each logins.filter((i) => i.claims.verified_email) as provider}
								<LoginProvider
									{provider}
									prefix={Array.isArray($data?.accounts) && $data?.accounts.includes(provider.slug)
										? 'Get another email from'
										: 'Get email from'}
									on:click={(e) => continueWithProvider(provider.slug, 'email', e.detail)}
								/>
							{/each}
						</ul>
					{/if}
				</Dropdown>
			</div>
		</div>
	{:else}
		<Dropdown
			hasBackground={false}
			dataTest="email-dropdown-btn"
			id="email-dropdown"
			ariaLabel={selected}
			{expanded}
			on:click={() => handleDropdown('emailDropdown')}
		>
			<div
				data-test="selected-email"
				class="px-2 w-full h-12 flex items-center justify-between no-underline"
				aria-hidden="true"
			>
				{selected}
			</div>
			{#if expanded}
				{@const emails = Array.isArray($data?.release?.emails)
					? dedupe($data.release.emails).filter((i) => i !== selected)
					: []}
				<div class="px-indent text-left w-full flex flex-col" transition:slide|local>
					<ul
						class="mt-0.5 {emails.length
							? 'border-y'
							: 'border-t'} border-[#808080] divide-y divide-[#808080]"
					>
						{#each emails as email, index (email)}
							<li>
								<button
									data-test={`email-${index}`}
									on:click={() => {
										selected = email;
										expanded = false;
									}}
									class="flex-shrink-0 h-12 w-full flex items-center justify-start no-underline"
									class:opacity-60={email === selected}
								>
									{email}
								</button>
							</li>
						{/each}
					</ul>

					<div class="my-2">
						<VerifyEmail on:otp={() => (emailOTPState = true)} on:success={verifyEmailSuccess} />
					</div>
					{#if !emailOTPState && logins.filter((i) => i.claims.verified_email).length}
						<ul class="space-y-2 mb-2">
							{#each logins.filter((i) => i.claims.verified_email) as provider}
								<li>
									<LoginProvider
										on:click={(e) => continueWithProvider(provider.slug, 'email', e.detail)}
										{provider}
										prefix="Get email from"
									/>
								</li>
							{/each}
						</ul>
					{/if}
				</div>
			{/if}
		</Dropdown>
	{/if}
</div>

<style>
	@media (prefers-color-scheme: dark) {
		.box-shadow {
			box-shadow: 0 0 0 1px #808080;
		}
	}

	@media (prefers-color-scheme: light) {
		.box-shadow {
			box-shadow: 0 0 0 1px #303030;
		}
	}

	ul li button:hover,
	ul li button:focus {
		box-shadow: 0px -2px 0px rgb(128, 128, 128), 0px 2px 0px rgb(128, 128, 128);
	}
</style>
