<script>
	import { _ } from 'svelte-i18n';
	import { slide } from 'svelte/transition';
	import { data, notification } from '$src/stores.js';
	import { dedupe } from '$utils/common';
	import { getWallet } from '$utils/ethereum';
	import { logPlausibleEvent } from '$utils/plausible';
	import Dropdown from '$lib/Dropdown.svelte';
	import LoginProvider from '$lib/LoginProvider.svelte';
	import EthereumProgressModal from '$lib/modal/EthereumProgressModal.svelte';
	import { postLinkEth, postLinkEthChallenge, getConsent } from '$utils/api.js';

	export let selected = '';
	export let expanded = false;

	export let handleDropdown = () => {};

	let addEthereumDropdown = false;
	let ethereumProgressModal = null; //Will hold extension or walletconnect values
	let ethereumProgressNotifs = [];
	// let web3ModalSign;

	async function continueWithEthereumExtension() {
		const [address] = await window.ethereum.request({ method: 'eth_requestAccounts' });
		ethereumProgressModal = 'extension';
		ethereumProgressNotifs = [
			...ethereumProgressNotifs,
			{
				text: $_('Wallet Connected ({address})', {
					values: {
						address: trimEthAddress(address)
					}
				}),
				type: 'success',
				status: $_('Waiting to sign')
			}
		];
		continueEthExtensionSigning(address);
	}

	async function continueEthExtensionSigning(address) {
		let challenge, signature;

		try {
			const res = await postLinkEth(address);
			logPlausibleEvent({ u: `/start/link/ethereum/extension/${getWallet().slug}`, n: 'action' });
			challenge = res.challenge;
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					status: $_('Waiting to sign')
				}
			];
		} catch (err) {
			console.error(err);
		}

		try {
			signature = await window.ethereum.request({
				method: 'personal_sign',
				params: [address, challenge]
			});
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					text: $_(`Message signed`),
					type: 'success',
					status: $_('Linking wallet')
				}
			];
		} catch (err) {
			console.info(err);
			if (err.code === 4001) {
				notification.show($_(`You've rejected the sign request`), 'error');
			} else {
				notification.show($_(`Something went wrong`), 'error');
			}
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
			return;
		}

		const body = {
			signature,
			address,
			icon: getWallet().icon,
			name: getWallet().name
		};

		try {
			await postLinkEthChallenge(body);
			logPlausibleEvent({ u: `/link/ethereum/extension/${getWallet().slug}`, n: 'action' });
			notification.show(
				$_('{provider} {label} has been added', {
					values: {
						provider: body.name,
						label: trimEthAddress(address)
					}
				}),
				'error'
			);
			notification.show(
				$_('{provider} {label} has been added', {
					values: {
						provider: body.name,
						label: trimEthAddress(address)
					}
				}),
				'error'
			);
			$data = await getConsent();
			if (!$data.merge) {
				selected = $data.release?.ethereums?.[0];
				expanded = false;
				if (addEthereumDropdown) {
					addEthereumDropdown = false;
				}
			}
		} catch (err) {
			console.error(err);
		} finally {
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
		}
	}

	const trimEthAddress = (addr) => {
		return addr.slice(0, 6) + '...' + addr.slice(38);
	};

	// Note: Uncomment to show WalletConnect
	// let session;
	// async function continueWithWalletConnect() {
	// 	try {
	// 		web3ModalSign = new WalletConnectModalSign(WALLETCONNECT_CONFIG);
	// 	} catch(err) {
	// 		console.error(err)
	// 		setTimeout(() => { //tbd : remove timeout - something is unsetting notification here
	// 			notification.show(
	// 			 'Something went wrong',
	// 			'error'
	// 			)
	// 		}, 150)
	// 		return;
	// 	}

	// 	if (session) {
	// 		await web3ModalSign.disconnect({
	// 			topic: session.topic
	// 		});
	// 	}
	// 	session = await web3ModalSign.connect({
	// 		requiredNamespaces: {
	// 			eip155: {
	// 				methods: ['personal_sign'],
	// 				chains: ['eip155:1'],
	// 				events: []
	// 			}
	// 		}
	// 	});
	// 	const address = getAddressFromAccount(session.namespaces.eip155.accounts[0]);
	// 	ethereumProgressModal = 'walletconnect';
	// 	ethereumProgressNotifs = [
	// 		...ethereumProgressNotifs,
	// 		{
	// 			text: $_('Wallet Connected ({address})', {
	// 				values: {
	// 					address: trimEthAddress(address)
	// 				}
	// 			}),
	// 			type: 'success',
	// 			status: $_('Waiting to sign')
	// 		}
	// 	];
	// 	//TODO WalletConnect v2 bug: https://github.com/wagmi-dev/wagmi/issues/2631
	// 	setTimeout(() => {
	// 		continueWalletConnectSigning(address)
	// 	}, 1000)
	// }

	// async function continueWalletConnectSigning(address) {
	// 	let challenge, signature;
	// 	const slug = session.peer.metadata.name.replace(/ /g, '-').toLowerCase();
	// 	try {
	// 		const res = await postLinkEth(address);
	// 		logPlausibleEvent({ u: `/start/link/ethereum/walletconnect/${slug}`, n: 'action' });
	// 		challenge = res.challenge;
	// 	} catch (err) {
	// 		console.error(err);
	// 		notification.show(
	// 			text: 'Something went wrong',
	// 			type: 'error'
	// 		);
	// 		return;
	// 	}
	// 	ethereumProgressNotifs = [
	// 		...ethereumProgressNotifs,
	// 		{
	// 			status: $_('Waiting to sign')
	// 		}
	// 	];
	// 	try {
	// 		signature = await web3ModalSign.request({
	// 			topic: session.topic,
	// 			chainId: 'eip155:1',
	// 			request: {
	// 				method: 'personal_sign',
	// 				params: [challenge, address]
	// 			}
	// 		});
	// 		ethereumProgressNotifs = [
	// 			...ethereumProgressNotifs,
	// 			{
	// 				text: $_(`Message signed`),
	// 				type: 'success',
	// 				status: $_('Linking wallet')
	// 			}
	// 		];
	// 	} catch (err) {
	// 		console.info(err);
	// 		notification.show(
	// 			$_(`You've rejected the sign request`),
	// 			'error'
	// 		);
	// 		ethereumProgressModal = null;
	// 		ethereumProgressNotifs = [];
	// 		return;
	// 	}

	// 	const icon =
	// 		session.peer.metadata.icons[0] ||
	// 		(session.peer.metadata?.url === 'https://metamask.io/'
	// 			? 'https://cdn.hello.coop/images/metamask.svg'
	// 			: 'https://cdn.hello.coop/images/ethereum.svg');
	// 	const body = {
	// 		signature,
	// 		address,
	// 		icon,
	// 		name: session.peer.metadata.name
	// 	};

	// 	try {
	// 		await postLinkEthChallenge(body);
	// 		logPlausibleEvent({
	// 			u: `/link/ethereum/walletconnect/${slug}`,
	// 			n: 'action'
	// 		});
	// 		notification.show(
	// 			$_('{provider} {label} has been added', {
	// 				values: {
	// 					provider: body.name,
	// 					label: trimEthAddress(address)
	// 				}
	// 			}),
	// 			'success'
	// 		);
	// 		$data = await getConsent();
	// 		if (!$data.merge) {
	// 			selected = $data.release?.ethereums?.[0];
	// 			expanded = false;
	// 			if (addEthereumDropdown) {
	// 				addEthereumDropdown = false;
	// 			}
	// 		}
	// 	} catch (err) {
	// 		console.error(err);
	// 	} finally {
	// 		ethereumProgressModal = null;
	// 		ethereumProgressNotifs = [];
	// 	}
	// }
</script>

{#if ethereumProgressModal && ethereumProgressNotifs.length}
	<EthereumProgressModal
		notifications={ethereumProgressNotifs}
		on:cancel={() => {
			ethereumProgressNotifs = [];
			ethereumProgressModal = null;
		}}
		on:ok={(e) => {
			if (ethereumProgressModal === 'extension') {
				continueEthExtensionSigning(e.detail);
			}
			// else if (ethereumProgressModal === 'walletconnect') {
			// 	continueWalletConnectSigning(e.detail);
			// }
		}}
	/>
{/if}

<div data-test="ethereum-container" class="space-y-1">
	<label for="eth-address" class="text-sm font-medium">
		{$_('Ethereum Address')}
	</label>
	{#if $data?.release?.update_scope}
		<div class="box-shadow space-y-2 p-2 rounded-md mt-2">
			<div data-test="eth-container" class="relative flex items-center">
				<div class="w-full">
					<ul class="flex flex-col items-start space-y-2 mt-1">
						{#each dedupe($data?.release?.ethereums) as ethObj, index (ethObj.address)}
							<li data-test="eth-{index}" class="flex items-center w-full">
								<input
									id="eth-{ethObj.address}"
									name="email"
									type="radio"
									bind:group={selected}
									value={ethObj}
									class="w-4 h-4 rounded-full form-radio text-charcoal dark:text-[#808080]"
								/>
								<label
									for="eth-{ethObj.address}"
									class="ml-2.5 inline-flex items-center truncate w-full"
								>
									<div class="w-full flex items-center justify-between">
										<div class="flex items-center gap-x-2">
											<img
												src={selected?.wallet?.icon}
												class="w-4.5 max-h-[18px]"
												alt={selected?.wallet?.name}
											/>
											<span aria-hidden="true"
												>{selected?.wallet?.name} ({selected?.wallet?.display})</span
											>
										</div>
									</div>
								</label>
							</li>
						{/each}
					</ul>
				</div>
			</div>

			<div class="relative">
				<Dropdown
					hasBackground={false}
					dataTest="add-provider-btn"
					ariaLabel="Add another email"
					expanded={addEthereumDropdown}
					on:click={() => (addEthereumDropdown = !addEthereumDropdown)}
				>
					<div class="h-12 flex items-center text-left px-4" aria-hidden="true">
						Add another wallet
					</div>

					{#if addEthereumDropdown}
						<ul class="space-y-2 p-2" transition:slide|local>
							{#if window.ethereum?.isMetaMask}
								<LoginProvider
									on:click={continueWithEthereumExtension}
									provider={{ display: 'MetaMask', slug: 'metamask' }}
									prefix="Get from"
								/>
							{:else if window.ethereum}
								<LoginProvider
									on:click={continueWithEthereumExtension}
									provider={{ display: 'Ethereum', slug: 'ethereum' }}
									prefix="Get from"
								/>
							{/if}

							<!-- Note: Uncomment to show WalletConnect -->
							<!-- <button
                            class="group w-full relative btn-border overflow-hidden px-4 flex items-center justify-start bg-transparent"
                            on:click={continueWithWalletConnect}
                        >
                            <div class="flex items-center gap-x-4">
                                <img
                                    src="https://cdn.hello.coop/images/walletconnect.svg"
                                    alt="WalletConnect"
                                    class="w-4.5 max-h-[18px]"
                                />
                                <span class="block text-left">
                                    {$_('Get with {provider}', {
                                        values: { provider: 'WalletConnect' }
                                    })}
                                </span>
                            </div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="flex-shrink-0 {$locale && $locale.startsWith('ar')
                                    ? 'mr-auto rotate-90'
                                    : 'ml-auto -rotate-90'} h-4.5 opacity-80 transform group-focus:stroke-3 group-hover:stroke-3"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </button> -->
						</ul>
					{/if}
				</Dropdown>
			</div>
		</div>
	{:else}
		<Dropdown
			hasBackground={false}
			id="eth-address"
			{expanded}
			ariaLabel={`${selected?.wallet?.name} (${selected?.wallet?.display})`}
			on:click={() => handleDropdown('ethAddressDropdown')}
		>
			<div class="px-2 w-full h-12 flex items-center justify-between">
				<div class="flex items-center gap-x-3">
					<img
						src={selected?.wallet?.icon}
						class="w-4.5 max-h-[18px]"
						alt={selected?.wallet?.name}
					/>
					<span aria-hidden="true">{selected?.wallet?.name} ({selected?.wallet?.display})</span>
				</div>
			</div>

			{#if expanded}
				{@const ethereums = Array.isArray($data?.release?.ethereums)
					? dedupe($data.release.ethereums, 'address').filter((i) => i.address !== selected.address)
					: []}
				<div class="px-indent rounded-md text-left w-full flex flex-col" transition:slide|local>
					<ul
						class="mt-0.5 {ethereums.length
							? 'border-y'
							: 'border-t'} border-[#808080] divide-y divide-[#808080]"
					>
						{#each ethereums as ethObj (ethObj.address)}
							<li>
								<button
									on:click={() => {
										selected = ethObj;
										expanded = false;
									}}
									class="flex-shrink-0 h-12 w-full flex items-center justify-start"
								>
									<div class="flex items-center gap-x-3">
										<img
											src={ethObj?.wallet?.icon}
											class="w-4.5 max-h-[18px]"
											alt={ethObj?.wallet?.display}
										/>
										<span>{ethObj?.wallet?.name} ({ethObj?.wallet?.display})</span>
									</div>
								</button>
							</li>
						{/each}
					</ul>

					<div class="my-2 space-y-2">
						{#if window.ethereum?.isMetaMask}
							<LoginProvider
								on:click={continueWithEthereumExtension}
								provider={{ display: 'MetaMask', slug: 'metamask' }}
								prefix="Get from"
							/>
						{:else if window.ethereum}
							<LoginProvider
								on:click={continueWithEthereumExtension}
								provider={{ display: 'Ethereum', slug: 'ethereum' }}
								prefix="Get from"
							/>
						{/if}

						<!-- Note: Uncomment to show WalletConnect -->
						<!-- <button
                        class="group w-full relative btn-border overflow-hidden px-4 flex items-center justify-start bg-transparent"
                        on:click={continueWithWalletConnect}
                    >
                        <div class="flex items-center gap-x-4">
                            <img
                                src="https://cdn.hello.coop/images/walletconnect.svg"
                                alt="WalletConnect"
                                class="w-4.5 max-h-[18px]"
                            />
                            <span class="block text-left">
                                {$_('Get with {provider}', {
                                    values: { provider: 'WalletConnect' }
                                })}
                            </span>
                        </div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="flex-shrink-0 {$locale && $locale.startsWith('ar')
                                ? 'mr-auto rotate-90'
                                : 'ml-auto -rotate-90'} h-4.5 opacity-80 transform group-focus:stroke-3 group-hover:stroke-3"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M19 9l-7 7-7-7"
                            />
                        </svg>
                    </button> -->
					</div>
				</div>
			{/if}
		</Dropdown>
	{/if}
</div>
