<script>
	import { _ } from 'svelte-i18n';
	import { getConsent, deleteCookies, logout } from '$utils/api.js';
	import { data, showSpinner, isRemoteAuthClient } from '$src/stores.js';
	import { push as navigateTo } from 'svelte-spa-router';

	async function logoutUser() {
		try {
			$showSpinner = true;
			await logout({ clearSession: false });
			$data = await getConsent(sessionStorage.authorize_query_params);
		} catch (err) {
			console.error(err);
		} finally {
			$showSpinner = false;
			navigateTo('/login');
		}
	}

	let forgetMeAjax = false;
	async function forgetMeHandler() {
		try {
			forgetMeAjax = true;
			$showSpinner = true;
			await deleteCookies();
			$data = await getConsent(sessionStorage.authorize_query_params);
		} catch (err) {
			console.error(err);
		} finally {
			$showSpinner = false;
			forgetMeAjax = false;
			navigateTo('/login');
		}
	}
</script>

<header
	data-test="authorize-title-bar"
	class="pt-1 px-1 border-b border-[#c8c8c8] dark:border-[#656565] flex justify-between"
>
	<div>{@html $_('hello_container.hello_btn')}</div>
	{#if ($data?.isPersonalLoggedIn || $data?.isManagedLoggedIn) && !$isRemoteAuthClient}
		<button
			data-test="logout-btn"
			on:click={logoutUser}
			class="focus:underline hover:underline text-sm opacity-80"
		>
			{$_('Log out')}
		</button>
	{:else if $data?.preferred || $data?.login_hint}
		<button
			data-test="forget-me-btn"
			on:click={forgetMeHandler}
			disabled={forgetMeAjax}
			class="text-sm opacity-80 hover:underline focus:underline"
		>
			{forgetMeAjax ? $_('Forgetting') : $_('Forget me')}
		</button>
	{/if}
</header>
