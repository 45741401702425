<script>
	import { onMount, createEventDispatcher } from 'svelte';
	import { data, notification, showSpinner } from '$src/stores.js';
	import { _ } from 'svelte-i18n';
	import { push as navigateTo } from 'svelte-spa-router';
	import { getRecommendedProviders } from '$utils/common';
	import { logPlausibleEvent } from '$utils/plausible';
	import { generateQR } from '$utils/qr';
	import RecommendedProviders from './login/RecommendedProviders.svelte';
	import {
		getConsent,
		postLoginProvider,
		postLoginEth,
		postLoginEthChallenge
	} from '$utils/api.js';
	import { push } from 'svelte-spa-router';
	import { getDeviceTheme } from '$utils/common.js';
	import { trimEthAddress, getWallet } from '$utils/ethereum.js';
	import { handleConsentResponse } from '$utils/consent.js';
	import { IS_PROD } from '$src/constants.js';
	// import { WALLETCONNECT_CONFIG } from '$src/constants.js';
	// import { WalletConnectModalSign } from '@walletconnect/modal-sign-html';
	// import { getAddressFromAccount } from '$utils';
	import logins from '$svr/providers/logins.json';
	import LoginPreferred from './login/LoginPreferred.svelte';
	import LoginManaged from './login/LoginManaged.svelte';
	import RecoveryStatus from './login/RecoveryStatus.svelte';
	import ManagedProvider from './ManagedProvider.svelte';
	import QRModal from './modal/QRModal.svelte';
	import UsePersonalAccount from './login/UsePersonalAccount.svelte';
	import LoginHint from './login/LoginHint.svelte';
	import EthereumProgressModal from '$lib/modal/EthereumProgressModal.svelte';

	const dispatch = createEventDispatcher();

	export let showQRLogin = false;

	//on logout, get original query params from session storage to make get consent call
	const authorizeQueryParams = sessionStorage.getItem('authorize_query_params');

	const provider_hints = {
		shown: [],
		hidden: []
	};

	let ethereumProgressModal;
	let ethereumProgressNotifs = [];
	let providerHints;
	// let web3ModalSign;
	let recommendedProviders = ['google', 'email'];
	let usePersonalAccount = false;
	let evtSource;
	let qrLink = '';
	let qrSvg = null;
	let showQRModal = false;
	//this runs everytime provider_hints are changed
	$: {
		recommendedProviders = getRecommendedProviders(provider_hints.shown, provider_hints.hidden);
	}

	const state = Object.preventExtensions({
		button: {
			show_recoveries: false,
			show_all: false,
			email: {
				default: false
			},
			phone: {
				default: false
			},
			provider: null,
			managed: false,
			personal: false
		},
		modal: {
			personalLoginRequired: false,
			managedLoginRequired: false,
			passkeyNotFound: false
		}
	});

	onMount(() => {
		useProviderHints();
	});

	function useProviderHints() {
		const searchParams = new URLSearchParams(authorizeQueryParams);
		providerHints =
			searchParams.get('provider_hint') ||
			searchParams.get('provider_hints') ||
			$data.provider_hint ||
			$data.provider_hints;
		if (providerHints) {
			const possibleSlugs = [
				...logins.filter((i) => !i.no_login).map((i) => i.slug),
				'email',
				'ethereum',
				'qrcode',
				'passkey'
			];
			let unknownSlugs = [];
			for (let provider of providerHints.split(' ')) {
				if (provider && provider.endsWith('--')) {
					provider = provider.substring(0, provider.length - 2);

					if (!possibleSlugs.includes(provider)) {
						unknownSlugs.push(provider);
						continue;
					}

					provider_hints.hidden = [...provider_hints.hidden, provider];
					continue;
				}

				if (!possibleSlugs.includes(provider)) {
					unknownSlugs.push(provider);
					continue;
				}

				provider_hints.shown = [...provider_hints.shown, provider];
			}

			if (unknownSlugs.length) {
				console.warn(
					`Unsupported provider_hint values: ${unknownSlugs.join(
						', '
					)}\nPlease see https://www.hello.dev/docs/apis/wallet/#provider_hint for supported values.`
				);
			}
		}
	}

	async function contactLogin({ email } = {}) {
		try {
			const res = await getConsent();
			$data = res;

			//AZ Funnel
			const client_id = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
				'client_id'
			);
			const redirect_uri = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
				'redirect_uri'
			);
			let redirect;
			try {
				redirect = new URL(redirect_uri)?.hostname;
			} catch (err) {
				console.error(err);
			}

			//New User Release Funnel
			const indexOfCurrentFunnelStep = window.authorizeFunnelSteps.indexOf(
				sessionStorage.az_release_funnel
			);
			const indexOfNextFunnelStep = window.authorizeFunnelSteps.indexOf('az_login_success');
			//session funnel state is valid and not already sent
			if (indexOfCurrentFunnelStep !== -1 && indexOfNextFunnelStep > indexOfCurrentFunnelStep) {
				//existing user: not auto-flow + isNewUser + not rerelease
				if (!$data?.uri && !$data?.response_mode && $data?.isNewUser && !$data?.release?.previous) {
					await logPlausibleEvent({
						n: 'AZ Login Success',
						p: { client_id, provider: email ? 'email' : 'phone', redirect },
						u: '/login'
					});
					sessionStorage.setItem('az_release_funnel', 'az_login_success');
				} else {
					await logPlausibleEvent({
						n: 'AZ Existing User',
						p: { client_id, provider: email ? 'email' : 'phone', redirect },
						u: '/login'
					});
					sessionStorage.removeItem('az_release_funnel');
				}
			}

			if (res.uri && res.response_mode) {
				$showSpinner = true;
				handleConsentResponse(res);
				return res;
			}
		} catch (err) {
			console.error(err);
			try {
				// err not always json -- this can fail as well
				const json = await err.json();
				if (err.status === 403) {
					if (json?.error?.message === 'ACCESS_DENIED') {
						notification.show('User does not have access to Dev Redirect URIs', 'error');
					}
				}
			} catch (err) {
				console.error(err);
				notification.show($_('Something went wrong. Please try again later.'), 'error');
			}
		}
	}

	async function contactLoginSuccess(e) {
		const res = await contactLogin(e.detail);
		if (!res?.uri && !res?.response_mode) {
			push('/');
		}
	}

	function handleContactError(err) {
		if (err.detail.status !== 404) {
			state.button.show_all = state.button.phone.default = state.button.email.default = false;
		}
	}

	async function continueWithEthereumExtension({
		info,
		account,
		accountSelected,
		accountToUse,
		useDifferentManaged
	} = {}) {
		const [address] = await window.ethereum.request({ method: 'eth_requestAccounts' });
		ethereumProgressModal = 'extension';
		ethereumProgressNotifs = [
			...ethereumProgressNotifs,
			{
				text: $_('Wallet Connected ({address})', {
					values: {
						address: trimEthAddress(address)
					}
				}),
				type: 'success',
				status: $_('Waiting to sign')
			}
		];
		if (info?.login_hint && info?.login_hint.toLowerCase() !== address.toLowerCase()) {
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					text: $_('Expected ({address})', {
						values: {
							address: trimEthAddress(info.login_hint)
						}
					}),
					type: 'error',
					status: address
				}
			];
			return;
		}
		continueEthExtensionSigning(
			address,
			account,
			accountSelected,
			accountToUse,
			useDifferentManaged
		);
	}

	async function continueEthExtensionSigning(
		address,
		account,
		accountSelected,
		accountToUse,
		useDifferentManaged
	) {
		let challenge, signature;
		const slug = getWallet().slug;
		try {
			const res = await postLoginEth(address);
			logPlausibleEvent({ u: `/start/login/ethereum/extension/${slug}`, n: 'action' });
			challenge = res.challenge;
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					status: $_('Waiting to sign')
				}
			];
		} catch (err) {
			console.error(err);
		}

		//New User Release Funnel
		const indexOfCurrentFunnelStep = window.authorizeFunnelSteps.indexOf(
			sessionStorage.az_release_funnel
		);
		const indexOfNextFunnelStep = window.authorizeFunnelSteps.indexOf('az_login_start');
		//session funnel state is valid and not already sent + is authorize app
		if (indexOfCurrentFunnelStep !== -1 && indexOfNextFunnelStep > indexOfCurrentFunnelStep) {
			const client_id = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
				'client_id'
			);
			const isRecommendedProvider = recommendedProviders.includes(slug);
			const redirect_uri = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
				'redirect_uri'
			);
			let redirect;
			try {
				redirect = new URL(redirect_uri)?.hostname;
			} catch (err) {
				console.error(err);
			}
			logPlausibleEvent({
				n: 'AZ Login Start',
				p: { client_id, provider: slug, recommended_provider: isRecommendedProvider, redirect },
				u: '/login'
			});
			sessionStorage.setItem('az_release_funnel', 'az_login_start');
		}

		try {
			signature = await window.ethereum.request({
				method: 'personal_sign',
				params: [address, challenge]
			});
			ethereumProgressNotifs = [
				...ethereumProgressNotifs,
				{
					text: $_('Message signed'),
					type: 'success',
					status: $_('Logging you in')
				}
			];
		} catch (err) {
			console.info(err);
			if (err.code === 4001) {
				notification.show($_("You've rejected the sign request"), 'error');
			} else {
				notification.show($_('Something went wrong. Please try again later.'), 'error');
			}
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
			return;
		}

		const body = {
			signature,
			address,
			icon: getWallet().icon,
			name: getWallet().name,
			account,
			accountSelected,
			accountToUse,
			useDifferentManaged
		};

		try {
			const res = await postLoginEthChallenge(body);
			await logPlausibleEvent({ u: `/login/ethereum/extension/${getWallet().slug}`, n: 'action' });
			$data = await getConsent();

			//New User Release Funnel

			const indexOfCurrentFunnelStep = window.authorizeFunnelSteps.indexOf(
				sessionStorage.az_release_funnel
			);
			const indexOfNextFunnelStep = window.authorizeFunnelSteps.indexOf('az_login_success');
			//session funnel state is valid and not already sent + is authorize app
			if (indexOfCurrentFunnelStep !== -1 && indexOfNextFunnelStep > indexOfCurrentFunnelStep) {
				const client_id = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
					'client_id'
				);
				const redirect_uri = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
					'redirect_uri'
				);
				let redirect;
				try {
					redirect = new URL(redirect_uri)?.hostname;
				} catch (err) {
					console.error(err);
				}
				if (!$data?.uri && !$data?.response_mode && $data?.isNewUser && !$data?.release?.previous) {
					//existing user: not auto-flow + isNewUser + not rerelease
					await logPlausibleEvent({
						n: 'AZ Login Success',
						p: { client_id, provider: slug, redirect },
						u: '/login'
					});
					sessionStorage.setItem('az_release_funnel', 'az_login_success');
				} else {
					await logPlausibleEvent({
						n: 'AZ Existing User',
						p: { client_id, provider: slug, redirect },
						u: '/login'
					});
					sessionStorage.removeItem('az_release_funnel');
				}
			}

			if (!res?.uri && !res?.response_mode) {
				push('/');
			} else {
				$showSpinner = true;
				handleConsentResponse(res);
			}
		} catch (err) {
			console.error(err);
			const json = await err.json();
			if (err.status === 403) {
				if (json?.error?.message === 'ACCESS_DENIED') {
					notification.show('User does not have access to Dev Redirect URIs', 'error');
				}
			}
			ethereumProgressModal = null;
			ethereumProgressNotifs = [];
		}
	}

	async function continueWithProvider({ slug, body, server, preferred } = {}) {
		try {
			$showSpinner = true;

			const isPersonalLoggedIn = $data?.isPersonalLoggedIn;
			const promptLogin = $data?.promptLogin;
			//dont flow through google if preferred already logged in
			if (preferred && isPersonalLoggedIn && !promptLogin) {
				try {
					$data = await getConsent('?accountSelected=personal');
				} catch (err) {
					//we handle the error in release page
					window.history.replaceState({}, document.title, window.location.pathname);
					return window.location.reload();
				}
				if ($data.uri && $data.response_mode) return handleConsentResponse($data); //go back to app with response

				$showSpinner = false;
				return push('/');
			}

			const { redirect } = await postLoginProvider({
				slug,
				body,
				server
			});

			//New User Release Funnel
			const indexOfCurrentFunnelStep = window.authorizeFunnelSteps.indexOf(
				sessionStorage.az_release_funnel
			);
			const indexOfNextFunnelStep = window.authorizeFunnelSteps.indexOf('az_login_start');
			//session funnel state is valid and not already sent + is authorize app
			if (indexOfCurrentFunnelStep !== -1 && indexOfNextFunnelStep > indexOfCurrentFunnelStep) {
				const client_id = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
					'client_id'
				);
				const isRecommendedProvider = recommendedProviders.includes(slug);
				const redirect_uri = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
					'redirect_uri'
				);
				let redirect;
				try {
					redirect = new URL(redirect_uri)?.hostname;
				} catch (err) {
					console.error(err);
				}
				await logPlausibleEvent({
					n: 'AZ Login Start',
					p: {
						client_id,
						provider: slug,
						recommended_provider: isRecommendedProvider,
						redirect
					},
					u: '/login'
				});
				sessionStorage.setItem('az_release_funnel', 'az_login_start');
			}
			window.location.href = redirect;
		} catch (err) {
			$showSpinner = false;
			console.error(err);
		}
	}

	// Note: Uncomment to show WalletConnect
	// let session;
	// async function continueWithWalletConnect({ info, accountSelected, accountToUse } = {}) {
	// 	try {
	// 		web3ModalSign = new WalletConnectModalSign(WALLETCONNECT_CONFIG);
	// 	} catch (err) {
	// 		console.error(err);
	// 		setTimeout(() => {
	// 			//tbd : remove timeout - something is unsetting notification here
	// 			notification.show(
	// 				'Something went wrong',
	// 				'error'
	// 			);
	// 		}, 150);
	// 		return;
	// 	}

	// 	if (!info?.login_hint && session) {
	// 		await web3ModalSign.disconnect({
	// 			topic: session.topic
	// 		});
	// 	}
	// 	session = await web3ModalSign.connect({
	// 		requiredNamespaces: {
	// 			eip155: {
	// 				methods: ['personal_sign'],
	// 				chains: ['eip155:1'],
	// 				events: []
	// 			}
	// 		}
	// 	});
	// 	const address = getAddressFromAccount(session.namespaces.eip155.accounts[0]);
	// 	ethereumProgressModal = 'walletconnect';
	// 	ethereumProgressNotifs = [
	// 		...ethereumProgressNotifs,
	// 		{
	// 			text: $_('Wallet Connected ({address})', {
	// 				values: {
	// 					address: trimEthAddress(address)
	// 				}
	// 			}),
	// 			type: 'success',
	// 			status: $_('Waiting to sign')
	// 		}
	// 	];
	// 	if (info?.login_hint && info?.login_hint.toLowerCase() !== address.toLowerCase()) {
	// 		ethereumProgressNotifs = [
	// 			...ethereumProgressNotifs,
	// 			{
	// 				text: $_('Expected ({address})', {
	// 					values: {
	// 						address: trimEthAddress(info.login_hint)
	// 					}
	// 				}),
	// 				type: 'error',
	// 				status: address
	// 			}
	// 		];
	// 		return;
	// 	}
	// 	//TODO WalletConnect v2 bug: https://github.com/wagmi-dev/wagmi/issues/2631
	// 	setTimeout(() => {
	// 		continueWalletConnectSigning(address, accountSelected, accountToUse);
	// 	}, 1000);
	// }

	// async function continueWalletConnectSigning(address, accountSelected, accountToUse) {
	// 	let challenge, signature;
	// 	const slug = session.peer.metadata.name.replace(/ /g, '-').toLowerCase();
	// 	try {
	// 		const res = await postLoginEth(address);
	// 		logPlausibleEvent({ u: `/start/login/ethereum/walletconnect/${slug}`, n: 'action' });
	// 		challenge = res.challenge;
	// 	} catch (err) {
	// 		console.error(err);
	// 		notification.show(
	// 			'Something went wrong',
	// 			'error'
	// 		);
	// 		return;
	// 	}

	// 	//New User Release Funnel
	// 	const indexOfCurrentFunnelStep = window.authorizeFunnelSteps.indexOf(
	// 		sessionStorage.az_release_funnel
	// 	);
	// 	const indexOfNextFunnelStep = window.authorizeFunnelSteps.indexOf('az_login_start');
	// 	//session funnel state is valid and not already sent + is authorize app
	// 	if (indexOfCurrentFunnelStep !== -1 && indexOfNextFunnelStep > indexOfCurrentFunnelStep) {
	// 		const client_id = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
	// 			'client_id'
	// 		);
	// 		const isRecommendedProvider = recommendedProviders.includes(slug);
	// 		const redirect_uri = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
	// 			'redirect_uri'
	// 		);
	// 		let redirect;
	// 		try {
	// 			redirect = new URL(redirect_uri)?.hostname;
	// 		} catch (err) {
	// 			console.error(err);
	// 		}
	// 		await logPlausibleEvent({
	// 			n: 'AZ Login Start',
	// 			p: { client_id, provider: slug, recommended_provider: isRecommendedProvider, redirect },
	// 			u: '/login'
	// 		});
	// 		sessionStorage.setItem('az_release_funnel', 'az_login_start');
	// 	}

	// 	ethereumProgressNotifs = [
	// 		...ethereumProgressNotifs,
	// 		{
	// 			status: $_('Waiting to sign')
	// 		}
	// 	];
	// 	try {
	// 		signature = await web3ModalSign.request({
	// 			topic: session.topic,
	// 			chainId: 'eip155:1',
	// 			request: {
	// 				method: 'personal_sign',
	// 				params: [challenge, address]
	// 			}
	// 		});
	// 		ethereumProgressNotifs[1] = {
	// 			text: $_('Message signed'),
	// 			type: 'success',
	// 			status: $_('Logging you in')
	// 		};
	// 	} catch (err) {
	// 		console.info(err);
	// 		notification.show(
	// 			$_(`You've rejected the sign request`),
	// 			'error'
	// 		);
	// 		ethereumProgressModal = null;
	// 		ethereumProgressNotifs = [];
	// 		return;
	// 	}

	// 	const icon =
	// 		session.peer.metadata.icons[0] ||
	// 		(session.peer.metadata?.url === 'https://metamask.io/'
	// 			? 'https://cdn.hello.coop/images/metamask.svg'
	// 			: 'https://cdn.hello.coop/images/ethereum.svg');
	// 	const body = {
	// 		signature,
	// 		address,
	// 		icon,
	// 		name: session.peer.metadata.name,
	// 		accountSelected,
	// 		accountToUse
	// 	};

	// 	try {
	// 		const res = await postLoginEthChallenge(body);
	// 		await logPlausibleEvent({
	// 			u: `/login/ethereum/walletconnect/${slug}`,
	// 			n: 'action'
	// 		});
	// 		$data = await getConsent();

	// 		//New User Release Funnel
	// 		const indexOfCurrentFunnelStep = window.authorizeFunnelSteps.indexOf(
	// 			sessionStorage.az_release_funnel
	// 		);
	// 		const indexOfNextFunnelStep = window.authorizeFunnelSteps.indexOf('az_login_success');
	// 		//session funnel state is valid and not already sent + is authorize app
	// 		if (indexOfCurrentFunnelStep !== -1 && indexOfNextFunnelStep > indexOfCurrentFunnelStep) {
	// 			const client_id = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
	// 				'client_id'
	// 			);
	// 			const redirect_uri = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
	// 				'redirect_uri'
	// 			);
	// 			let redirect;
	// 			try {
	// 				redirect = new URL(redirect_uri)?.hostname;
	// 			} catch (err) {
	// 				console.error(err);
	// 			}
	// 			if (!$data?.uri && !$data?.response_mode && $data?.isNewUser && !$data?.release?.previous) {
	// 				//existing user: not auto-flow + isNewUser + not rerelease
	// 				await logPlausibleEvent({
	// 					n: 'AZ Login Success',
	// 					p: { client_id, provider: slug, redirect },
	// 					u: '/login'
	// 				});
	// 				sessionStorage.setItem('az_release_funnel', 'az_login_success');
	// 			} else {
	// 				await logPlausibleEvent({
	// 					n: 'AZ Existing User',
	// 					p: { client_id, provider: slug, redirect },
	// 					u: '/login'
	// 				});
	// 				sessionStorage.removeItem('az_release_funnel');
	// 			}
	// 		}

	// 		if (!res?.uri && !res?.response_mode) {
	// 			push('/');
	// 		} else {
	// 			$showSpinner = true;
	// 			handleConsentResponse(res);
	// 		}
	// 	} catch (err) {
	// 		console.error(err);
	// 		const json = await err.json();
	// 		if (err.status === 403) {
	// 			if (json?.error?.message === 'ACCESS_DENIED') {
	// 				notification.show(
	// 					'User does not have access to Dev Redirect URIs',
	// 					'error'
	// 				);
	// 			}
	// 		}
	// 		ethereumProgressModal = null;
	// 		ethereumProgressNotifs = [];
	// 	}
	// }

	async function handleManagedEmailSuccess() {
		try {
			$data = await getConsent();
			return push('/');
		} catch (err) {
			console.error(err);
		}
	}

	let continueWithQRCodeAjax = false;
	function continueWithQRCode() {
		continueWithQRCodeAjax = true;
		const searchParams = new URLSearchParams();
		searchParams.set('prefers-color-scheme', getDeviceTheme());
		searchParams.set('language', window.navigator.language);
		if (providerHints) {
			const filteredProviderHints = providerHints
				//does not make sense to propagate the qrcode hint to remote auth client where its going to be hidden anyway
				.replaceAll('qrcode', '')
				.replaceAll('  ', ' ')
				.trim();
			if (filteredProviderHints) {
				searchParams.set('provider_hint', filteredProviderHints);
			}
		}
		evtSource = new EventSource('/api/v1/login/qrcode?' + searchParams.toString());
		evtSource.addEventListener('qr_code', async ({ data }) => {
			qrLink = data;
			qrSvg = await generateQR(data);
			continueWithQRCodeAjax = false;
			showQRModal = true;
		});
		evtSource.addEventListener('scanned', () => {
			evtSource.close();
			navigateTo('/remote');
		});
		evtSource.addEventListener('keep-alive', (event) => {
			if (!IS_PROD) {
				console.log('keep-alive: ' + event.data);
			}
		});
	}

	async function deleteQRCode() {
		try {
			showQRModal = false;
			qrLink = '';
			await fetch('/api/v1/login/qrcode', {
				method: 'DELETE'
			});
			if (evtSource) {
				evtSource.close();
			}
		} catch (err) {
			console.error(err);
		}
	}

	let showRecoveries = false;
	$: recoveryIds = $data?.recovery?.map((i) => i.id) || [];
	$: recoverable = recoveryIds.length >= 2;
	$: loggedInSubject = $data?.recovery?.find((i) => i.id === $data?.subjects[0]) || {};
	$: isRecoveryMode =
		!$data?.isPersonalLoggedIn &&
		!!$data?.subjects?.find((subject) => recoveryIds.includes(subject));
	$: isManagedRecoveryMode = !$data?.isPersonalLoggedIn && $data?.isManagedLoggedIn;
	$: hasPreferred = !!$data?.preferred?.length;
	$: hasManaged = !!$data?.managed?.length;
	$: isPersonalLoggedIn = $data?.isPersonalLoggedIn;
	$: isManagedLoggedIn = $data?.isManagedLoggedIn;
	$: showManaged = $data?.accountToUse !== 'personal';
	$: isPersonalView = $data?.accountToUse === 'personal';
	$: isManagedView = $data?.accountToUse === 'managed';
	$: authnWithPreferred = hasPreferred && !isPersonalLoggedIn;
	$: authnWithManaged = !hasPreferred && hasManaged && !isManagedLoggedIn;
	$: loginHint = $data?.login_hint;
	$: requiresPersonal = $data?.release?.requiresPersonal;
</script>

{#if ethereumProgressModal && ethereumProgressNotifs.length}
	<EthereumProgressModal
		notifications={ethereumProgressNotifs}
		on:cancel={() => {
			ethereumProgressNotifs = [];
			ethereumProgressModal = null;
		}}
		on:ok={(e) => {
			if (ethereumProgressModal === 'extension') {
				continueEthExtensionSigning(e.detail);
			}
			// else if (ethereumProgressModal === 'walletconnect') {
			// 	continueWalletConnectSigning(e.detail);
			// }
		}}
	/>
{/if}

{#if showQRModal}
	<QRModal {deleteQRCode} {qrSvg} {qrLink} />
{/if}

{#if requiresPersonal}
	<h1 class="relative flex items-center justify-center text-lg font-medium mb-6">
		{$_('Requires a personal account')}
	</h1>

	{#if hasPreferred}
		<LoginPreferred
			accountSelected="managed"
			accounts={$data.preferred}
			{contactLoginSuccess}
			{handleContactError}
			{continueWithEthereumExtension}
			{continueWithProvider}
		/>
	{:else}
		<RecommendedProviders
			accountSelected="managed"
			{recommendedProviders}
			{continueWithQRCodeAjax}
			showManagedLogin={false}
			{contactLoginSuccess}
			{handleContactError}
			{continueWithEthereumExtension}
			{continueWithProvider}
			{showQRLogin}
			on:qrcode={() => dispatch('qrcode')}
		/>
	{/if}
{:else if loginHint}
	<LoginHint
		login_hint={loginHint}
		{continueWithProvider}
		{contactLoginSuccess}
		{handleContactError}
		{handleManagedEmailSuccess}
		{continueWithEthereumExtension}
	/>
{:else if isPersonalView}
	{#if isRecoveryMode}
		<RecoveryStatus
			accountSelected="personal"
			{continueWithProvider}
			{contactLoginSuccess}
			{handleContactError}
			{continueWithEthereumExtension}
			{loggedInSubject}
		/>
	{/if}

	{#if hasManaged && !hasPreferred && !isPersonalLoggedIn && !isManagedLoggedIn}
		<LoginManaged
			heading={$_('Requires a personal account')}
			label={$_('To set one up:<br/>Log in with your managed account')}
			accountToUse="personal"
			accounts={$data.managed}
			{continueWithProvider}
			{authnWithPreferred}
			{authnWithManaged}
			{contactLoginSuccess}
			{handleContactError}
			{continueWithEthereumExtension}
		/>
	{:else if !isRecoveryMode || (isManagedRecoveryMode && !recoverable)}
		{#if hasPreferred}
			<LoginPreferred
				accountSelected="personal"
				label={$_('Use your preferred login provider')}
				accounts={$data.preferred}
				{contactLoginSuccess}
				{handleContactError}
				{continueWithEthereumExtension}
				{continueWithProvider}
			/>
		{:else}
			<RecommendedProviders
				accountSelected="personal"
				label={$_('Select your preferred login provider')}
				{recommendedProviders}
				{continueWithQRCodeAjax}
				showManagedLogin={showManaged}
				{contactLoginSuccess}
				{handleContactError}
				{continueWithEthereumExtension}
				{continueWithProvider}
				{handleManagedEmailSuccess}
				{showQRLogin}
				on:qrcode={() => dispatch('qrcode')}
			/>
		{/if}
	{/if}
{:else if isManagedView}
	{#if hasPreferred && !isPersonalLoggedIn && !hasManaged}
		<LoginPreferred
			accountToUse="managed"
			heading={$_('Requires a managed account')}
			label={$_('To set one up:<br/>Log in with your personal account')}
			accounts={$data.preferred}
			{contactLoginSuccess}
			{handleContactError}
			{continueWithEthereumExtension}
			{continueWithProvider}
		/>
	{:else if hasManaged}
		<LoginManaged
			accountSelected="managed"
			accountToUse="managed"
			{authnWithPreferred}
			{authnWithManaged}
			accounts={$data.managed}
			{continueWithProvider}
			{contactLoginSuccess}
			{handleContactError}
			{continueWithEthereumExtension}
			{handleManagedEmailSuccess}
		/>
	{:else}
		<ManagedProvider
			login={true}
			accountSelected="managed"
			on:managedEmailSuccess={handleManagedEmailSuccess}
		/>
	{/if}
{:else if hasPreferred || hasManaged}
	<!-- Shows available account at top -->
	<div class="gap-y-6 flex flex-col" class:flex-col-reverse={!hasPreferred}>
		{#if !$data.preferred?.length}
			<div>
				<UsePersonalAccount
					bind:usePersonalAccount
					{authnWithManaged}
					{contactLoginSuccess}
					{handleContactError}
					{continueWithEthereumExtension}
					{continueWithProvider}
				/>

				{#if usePersonalAccount}
					<RecommendedProviders
						accountSelected="personal"
						{recommendedProviders}
						{continueWithQRCodeAjax}
						{showQRLogin}
						showManagedLogin={false}
						{contactLoginSuccess}
						{handleContactError}
						{continueWithEthereumExtension}
						{continueWithProvider}
						{handleManagedEmailSuccess}
						on:qrcode={() => dispatch('qrcode')}
					/>
				{/if}
			</div>
		{:else}
			<LoginPreferred
				accountSelected="personal"
				label={hasPreferred ? $_('Use your personal account') : ''}
				accounts={$data.preferred}
				{contactLoginSuccess}
				{handleContactError}
				{continueWithEthereumExtension}
				{continueWithProvider}
				bind:showRecoveries
			/>
		{/if}

		<!-- 
		hide managed accounts when recovery options are expanded
		so that 2 managed buttons are not confusing, one bein recovery and other being managed)
		-->
		{#if !showRecoveries}
			<LoginManaged
				label={hasManaged ? $_('Use your managed account') : ''}
				accountSelected="managed"
				{authnWithPreferred}
				{authnWithManaged}
				accounts={$data.managed}
				{continueWithProvider}
				{contactLoginSuccess}
				{handleContactError}
				{continueWithEthereumExtension}
			/>
		{/if}
	</div>
{:else}
	<RecommendedProviders
		label={$_('Select your preferred login provider')}
		{recommendedProviders}
		showManagedLogin={showManaged}
		accountSelected="unknown"
		{contactLoginSuccess}
		{handleContactError}
		{continueWithEthereumExtension}
		{continueWithProvider}
		{handleManagedEmailSuccess}
		on:qrcode={continueWithQRCode}
		{showQRLogin}
	/>
{/if}
