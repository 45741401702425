<script>
	import { _ } from 'svelte-i18n';
	import { replace as replaceRoute } from 'svelte-spa-router';
	import { data, notification, showSpinner, isRemoteAuthClient } from '$src/stores.js';
	import { onDestroy, onMount } from 'svelte';
	import { getConsent, deleteConsent, postLinkProvider } from '$utils/api.js';
	import { makeLabel } from '$utils/common.js';
	import { logPlausibleEvent } from '$utils/plausible.js';
	import VerifiedIcon from '$lib/icon/VerifiedIcon.svelte';
	import logins from '$svr/providers/logins.json';
	import LoginProvider from '$lib/LoginProvider.svelte';
	import AuthorizeLayout from '$lib/layout/AuthorizeLayout.svelte';
	import { IS_PROD } from '$src/constants.js';

	let authCancelledAtRemoteClient = false;
	let authCancelledAtInitClient = false;
	let evtSource;

	onMount(async () => {
		$showSpinner = true;

		//we dont have consent data
		if (!$data?.version) {
			try {
				$data = await getConsent();
			} catch {
				return replaceRoute('/login');
			}
		}

		if (!$data?.isPersonalLoggedIn && !$data?.isManagedLoggedIn) return replaceRoute('/login');

		//we alraedy got a verified_name
		if ($data.release?.verified_names?.length) return replaceRoute('/');

		if ($isRemoteAuthClient) {
			evtSource = new EventSource('/api/v1/login/qrcode/status');
			evtSource.addEventListener('cancel', () => {
				notification.show('Authorization was cancelled on the other device', 'error');
				authCancelledAtInitClient = true;
				evtSource.close();
			});
			evtSource.addEventListener('keep-alive', (event) => {
				if (!IS_PROD) {
					console.log('keep-alive: ' + event.data);
				}
			});
		}

		logPlausibleEvent({ u: '/wizard/verified-name' });

		$showSpinner = false;
	});

	onDestroy(() => {
		if (evtSource) {
			evtSource.close();
		}
	});

	async function continueWithProvider(slug, server) {
		try {
			$showSpinner = true;
			const { redirect } = await postLinkProvider({
				slug,
				attribute: 'verified-name',
				server
			});

			window.location.href = redirect;
		} catch (err) {
			$showSpinner = false;
			console.error(err);
		}
	}

	function handleWizardComplete() {
		return replaceRoute('/');
	}

	$: {
		if (hasVerifiedName) {
			handleWizardComplete();
		}
	}

	$: names = $data?.release?.verified_names && Object.keys($data?.release?.verified_names);

	$: verifiedNames =
		names && names.filter((i) => Object.keys($data?.release?.verified_names[i]).length >= 2);

	$: hasVerifiedName = Boolean(verifiedNames?.length);

	async function cancelConsent() {
		sessionStorage.removeItem('az_release_funnel');
		if ($isRemoteAuthClient) {
			try {
				await fetch('/api/v1/consent', { method: 'DELETE' });
				notification.show('Authorization was cancelled', 'error');
				authCancelledAtRemoteClient = true;
				if (evtSource) {
					evtSource.close();
				}
			} catch (err) {
				console.error(err);
			}
		} else {
			deleteConsent();
		}
	}
</script>

<AuthorizeLayout
	heading="Requires a name you already use in two different accounts"
	showTitleBar={!authCancelledAtRemoteClient && !authCancelledAtInitClient}
	showDeviceInfo={$isRemoteAuthClient && !authCancelledAtRemoteClient && !authCancelledAtInitClient}
	closePageState={authCancelledAtRemoteClient || authCancelledAtInitClient}
	{cancelConsent}
>
	<section class="max-w-md container mx-auto">
		{#if $data.release?.verified_names}
			<div>
				<h3 class="text-sm font-medium text-left flex items-center justify-start mr-auto">
					{$_('Name')}
					{#if !Object.keys($data.release.verified_names).filter((i) => Object.keys($data.release.verified_names[i]).length >= 2).length}
						<span data-test="no-name-fallback" class="italic ml-2 font-normal opacity-60">
							{$_('none verified yet')}
						</span>
					{/if}
				</h3>
				<ul class="flex flex-col items-start space-y-2 ml-4 mt-1 truncate">
					{#each Object.keys($data.release.verified_names) as verified_name, index}
						{@const isUnverified =
							Object.keys($data.release.verified_names[verified_name]).length < 2}
						<li
							data-test="name-{index}"
							class:opacity-60={isUnverified}
							class="inline-flex items-center truncate w-full"
						>
							<span class="inline-block w-7 mr-0.5 flex-shrink-0">
								<VerifiedIcon unverified={isUnverified} />
							</span>
							<span class="truncate"
								>{verified_name}
								{makeLabel($data.release.verified_names[verified_name])}</span
							>
						</li>
					{/each}
				</ul>
			</div>
		{/if}

		<h2
			data-test="page-cta"
			class="md:max-w-md text-lg font-medium mt-8 mb-6 mx-auto text-center block"
		>
			Link another account to<br /> verify your name
		</h2>

		<ul class="space-y-3">
			{#each logins.filter((i) => !hasVerifiedName && i.claims.existing_name) as provider}
				<LoginProvider
					{provider}
					prefix={Array.isArray($data?.accounts) && $data?.accounts.includes(provider.slug)
						? 'Link another'
						: 'Link'}
					on:click={(e) => continueWithProvider(provider.slug, e.detail)}
				/>
			{/each}
		</ul>
	</section>
</AuthorizeLayout>
