<script>
	import { _ } from 'svelte-i18n';
	import { replace as replaceRoute } from 'svelte-spa-router';
	import { onDestroy, onMount } from 'svelte';
	import { data, showSpinner, notification, isRemoteAuthClient } from '$src/stores.js';
	import { getConsent, postConsent, deleteConsent, postLinkProvider } from '$utils/api.js';
	import AuthorizeLayout from '$lib/layout/AuthorizeLayout.svelte';
	import { handleConsentResponse } from '$utils/consent.js';
	import { logPlausibleEvent } from '$utils/plausible.js';
	import { clearSessionStorage } from '$utils/storage.js';
	// import {
	// 	promptForPasskey
	// } from '$utils/passkey.js';
	// import { getLoginChallenge, postLoginChallenge } from '../../passkey.js';
	// import { startAuthentication } from '@simplewebauthn/browser';
	import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte';
	import FullNameSelect from '$lib/release/FullNameSelect.svelte';
	import PreferredNameSelect from '$lib/release/PreferredNameSelect.svelte';
	import FirstNameSelect from '$lib/release/FirstNameSelect.svelte';
	import LastNameSelect from '$lib/release/LastNameSelect.svelte';
	import AppReleaseInfo from '$lib/AppReleaseInfo.svelte';
	import PictureSelect from '$lib/release/PictureSelect.svelte';
	import APIAccessTable from '$lib/release/APIAccessTable.svelte';
	import VerifiedNameSelect from '$lib/release/VerifiedNameSelect.svelte';
	import EthereumSelect from '$lib/release/EthereumSelect.svelte';
	import EmailSelect from '$lib/release/EmailSelect.svelte';
	import PhoneSelect from '$lib/release/PhoneSelect.svelte';
	import ExistingNameSelect from '$lib/release/ExistingNameSelect.svelte';
	import ExistingUsername from '$lib/release/ExistingUsername.svelte';
	import ExternalLinkIcon from '$lib/icon/ExternalLinkIcon.svelte';
	import UsernameSelect from '$lib/release/UsernameSelect.svelte';
	import OrgInfo from '$lib/release/OrgInfo.svelte';
	import { IS_PROD } from '$src/constants.js';
	import Copy from '$lib/Copy.svelte';
	import CopyLink from '$lib/CopyLink.svelte';
	// import { WALLETCONNECT_CONFIG } from '$src/constants.js'
	// import { getAddressFromAccount } from '$utils';
	// import { WalletConnectModalSign } from '@walletconnect/modal-sign-html'

	let submitFormRequest = false;
	let emailOTPState = false;
	let phoneOTPState = false;

	let selectedName,
		selectedEmail,
		selectedPhone,
		selectedEthAddress,
		selectedPicture,
		selectedPreferredName,
		selectedFirstName,
		selectedLastName,
		selectedDiscord,
		selectedTwitter,
		selectedGithub,
		selectedGitlab;

	let selectedVerifiedName = '';
	let selectedExistingName = '';
	let selectedExistingUsername = '';

	let dropdownStates = {
		verifiedNameAccountsDropdown: false,
		existingNameAccountsDropdown: false,
		existingUsernameAccountsDropdown: false,
		nameDropdown: false,
		preferredNameDropdown: false,
		firstNameDropdown: false,
		lastNameDropdown: false,
		emailDropdown: false,
		phoneDropdown: false,
		pictureDropdown: false,
		ethAddressDropdown: false,
		discordAccountsDropdown: false,
		twitterAccountsDropdown: false,
		githubAccountsDropdown: false,
		gitlabAccountsDropdown: false
	};

	let loadingFailed = false;
	let noSession = false;
	let unsupportedRedirectUri = false;
	let unconfiguredRedirectUri = false;
	let accessDenied = false;

	//authorization was cancelled from initiating client
	let authCancelledAtInitClient = false;

	let authCancelledAtRemoteClient = false;

	let remoteAuthDone = false;
	let invalidQr = false;

	let errData = null;

	//Add i18n keys
	let errMap = {
		invalid_request: 'Invalid Request',
		unauthorized_client: 'Unauthorized Client',
		'invalid response_mode': 'Invalid Response Mode',
		access_denied: $_('Request cancelled'),
		user_cancelled_authorize: $_('Request cancelled'),
		unsupported_response_type: 'Unsupported Response Type',
		'must be code or id_token': 'Must be code or id token',
		server_error: 'Server Error',
		temporarily_unavailable: 'Temporarily Unavailable',
		invalid_client: 'Invalid Client',
		invalid_grant: 'Invalid Grant',
		unsupported_grant_type: 'Unsupported Grant Type',
		invalid_scope: 'Invalid Scope',
		'missing or invalid scope': 'Missing or invalid scope',
		'missing code_challenge': 'Missing code challenge',
		UNSUPPORTED_REDIRECT_URI: 'Redirect URI is not supported',
		ACCESS_DENIED: 'User does not have access to Dev Redirect URIs'
		// NO_SESSION_REQUEST: 'No Session'
	};

	const helloAppIds = ['hello_console', 'hello_quick_start'];

	let evtSource;

	const searchParams = new URLSearchParams(window.location.search);
	const hashParams = new URLSearchParams(window.location.hash.substring(1));

	onMount(async () => {
		$showSpinner = true;

		const errorFromProvider = hashParams.get('error');
		const loadedFromQR = hashParams.get('qr');
		const loadedFromApp = searchParams.get('client_id');
		const responseFromProvider = hashParams.has('provider');
		const sessionQueryParams = sessionStorage.getItem('authorize_query_params');

		if (errorFromProvider) {
			try {
				//this will always fail and go to catch block where we put up the notification
				$data = await getConsent('?' + window.location.hash.substring(1));
			} catch (err) {
				// err is string string
				notification.show(
					//normalize error to be lowercase because LINE sends ACCESS_DENIED (all uppercase)
					(typeof err === 'string' && errMap[err?.toLowerCase()]) ||
						$_('Something went wrong. Please try again later.'),
					'error'
				);
			}

			//get consent again since we want to show the previous state the user was in
			try {
				$data = await getConsent('', { showNotification: false }); //do not override the oauth error notification
			} catch (err) {
				return handleGetConsentError(err);
			}
		} else if (loadedFromQR) {
			try {
				$data = await getConsent('?qr=' + loadedFromQR);
			} catch (err) {
				notification.show('QR has already been scanned', 'error');
				invalidQr = true;
				return handleGetConsentError(err);
			}

			//DEBUG LOG
			if (!IS_PROD) {
				const logObj = {};
				for (const [key, value] of hashParams.entries()) {
					logObj[key] = value;
				}
				console.log('Hash Params:', JSON.stringify(logObj, null, 2));
			}
			//DEBUG LOG
		} else if (loadedFromApp) {
			//on logout, need original query params to make get consent call
			//useful if user wants to switch accounts
			//we strip out login_hint from query params because we want to get rid of login_hint prompt state if user wants to switch accounts
			const saveSearchParams = new URLSearchParams(window.location.search);
			saveSearchParams.delete('login_hint');
			sessionStorage.setItem('authorize_query_params', saveSearchParams.toString());

			try {
				$data = await getConsent(window.location.search);
			} catch (err) {
				return handleGetConsentError(err);
			}

			if (searchParams.has('scope') && searchParams.get('scope').includes('update_scope')) {
				logPlausibleEvent({ u: '/update_scope' });
			}

			//DEBUG LOG
			if (!IS_PROD) {
				const logObj = {};
				for (const [key, value] of searchParams.entries()) {
					logObj[key] = value;
				}
				console.log('Query Params:', JSON.stringify(logObj, null, 2));
			}
			//DEBUG LOG

			clearSessionStorage(['app', 'authorize_query_params', 'az_release_funnel']);
		} else if (responseFromProvider) {
			try {
				$data = await getConsent(location.hash.substring(1));
			} catch (err) {
				// user clicked back after we have already processed response from provider
				// in this case, we fetch get consent again
				if (err.message === 'NO_SESSION_RESPONSE') {
					try {
						$data = await getConsent();

						if ($data?.verifyManagedEmail) {
							// i18n
							notification.show('You must verify your email to proceed', 'error');
						} else {
							// i18n
							notification.show('Looks like you clicked the back button', 'error');
						}
					} catch (err) {
						return handleGetConsentError(err);
					}
				} else {
					return handleGetConsentError(err);
				}
			}
			logProviderResPlausibleEvents();
		} else if (sessionQueryParams && !$data?.version) {
			//we might have fetched data in other pages
			//we dont have consent data
			try {
				$data = await getConsent();
			} catch (err) {
				return handleGetConsentError(err);
			}
		} else if (!$data?.version) {
			//only redirect to wallet profile if data not fetched earlier
			//redirect to wallet - no session found
			window.location.href = window.location.origin;
			return;
		}

		// if (!IS_PROD && searchParams.has('die')) {
		// 	throw new Error('You have chosen....death!');
		// }

		//Done processing query/hash params the page got loaded with
		//Clear url query params (oauth errors, provider response, authorize query params, qr param)
		window.history.replaceState({}, document.title, window.location.pathname);

		//Got data to respond back to app
		if ($data.uri && $data.response_mode) {
			if (!$data?.params?.error) {
				await logPlausibleEvent({ u: '/auto' });
			}
			return handleConsentResponse($data);
		}

		//Choose if managed account / choose managed logo
		if ($data.chooseWhoManages || $data.chooseManagedLogo) {
			return replaceRoute('/wizard/managed');
		}

		//Passkey auto login
		// if (
		// 	!$data.isPersonalLoggedIn &&
		// 	!$data.isManagedLoggedIn &&
		// 	$data.hasPasskey &&
		// 	!sessionStorage.authorize_query_params?.includes('passkey--')
		// ) {
		// 	try {
		// 		const challenge = await getLoginChallenge();
		// 		const signedChallenge = await startAuthentication(challenge);
		// 		const webAuthnLoginRes = await postLoginChallenge(signedChallenge);
		// 		if (!webAuthnLoginRes?.verified) throw webAuthnLoginRes;
		// 		$data = await getConsent();
		// 	} catch (err) {
		// 		console.error(err);
		// 		notification.show(
		// 			'Authentication cancelled',
		// 			'error'
		// 		);
		// 	}
		// }

		//Prompt for passkey
		//uncomment to start prompting for passkey
		// const showPromptForPasskey = await promptForPasskey($data);
		// if (showPromptForPasskey) return replaceRoute('/wizard/passkey');

		//Authorization success at remote client / IAB
		if ($data.success || $data.inApp) {
			remoteAuthDone = true;
			notification.show('Authorization is complete', 'error');
			if (evtSource) {
				evtSource.close();
			}
			$showSpinner = false;
			return;
		}

		if ($data?.verifyManagedEmail) return replaceRoute('/wizard/email');

		const isLoggedIn = $data.isPersonalLoggedIn || $data?.isManagedLoggedIn;
		if (!isLoggedIn || !$data.release || $data.login_hint || $data?.release?.requiresPersonal)
			return replaceRoute('/login');

		if ($isRemoteAuthClient) {
			evtSource = new EventSource('/api/v1/login/qrcode/status');
			evtSource.addEventListener('cancel', () => {
				notification.show('Authorization was cancelled on the other device', 'error');
				authCancelledAtInitClient = true;
				evtSource.close();
			});
			evtSource.addEventListener('keep-alive', (event) => {
				if (!IS_PROD) {
					console.log('keep-alive: ' + event.data);
				}
			});
		}

		if (!wizardRoutes()) {
			logPlausibleEvent({ u: '/' });
			setStartingClaimValues();
			$showSpinner = false;
		}
	});

	async function handleGetConsentError(err) {
		// 200 errors
		if (err.message === 'NO_SESSION_REQUEST') {
			await logPlausibleEvent({ u: '/no-session' });
			noSession = true;
		} else if (err.message === 'UNSUPPORTED_REDIRECT_URI') {
			await logPlausibleEvent({ u: '/unsupported-redirect-uri' });
			unsupportedRedirectUri = true;
		} else if (err.message === 'UNCONFIGURED_REDIRECT_URI') {
			await logPlausibleEvent({ u: '/unconfigured-redirect-uri' });
			unconfiguredRedirectUri = true;
		} else if (err.message === 'ACCESS_DENIED') {
			await logPlausibleEvent({ u: '/access-denied' });
			accessDenied = true;
		}

		// ~400 errors
		if (err.json) {
			errData = err.json;
			if (!IS_PROD) {
				const logObj = {};
				for (const [key, value] of searchParams.entries()) {
					logObj[key] = value;
				}
				console.log('Query Params:', JSON.stringify(logObj, null, 2));
				console.log('Response:', JSON.stringify(errData, null, 2));
			}
			if (errData?.uri) {
				//if no response mode, default to fragment
				return handleConsentResponse(errData);
			}
			notification.show(
				errMap[errData?.error] || $_('Something went wrong. Please try again later.'),
				'error'
			);
		}

		console.error(err);
		window.history.replaceState({}, document.title, window.location.pathname);
		loadingFailed = true;
		$showSpinner = false;
	}

	onDestroy(() => {
		if (evtSource) {
			evtSource.close();
		}
	});

	function wizardRoutes() {
		const routes = {
			recovery: '/wizard/recovery',
			verified_names: '/wizard/verified-name',
			existing_names: '/wizard/existing-name',
			existing_usernames: '/wizard/existing-username',
			names: '/wizard/name',
			nicknames: '/wizard/preferred-name',
			given_names: '/wizard/first-name',
			family_names: '/wizard/last-name',
			emails: '/wizard/email',
			phones: '/wizard/phone',
			ethereums: '/wizard/ethereum',
			discords: '/wizard/discord',
			twitters: '/wizard/twitter',
			githubs: '/wizard/github',
			gitlabs: '/wizard/gitlab'
		};
		const requested_scopes = Object.keys($data.release);
		const order_of_routes = Object.keys(routes);

		for (const route of order_of_routes) {
			if (!requested_scopes.includes(route)) continue;

			if (route === 'recovery') {
				if ($data.recovery?.length < 2) {
					return replaceRoute(routes[route]);
				}
			} else if (route === 'verified_names') {
				if (
					$data.release?.verified_names &&
					!Object.keys($data.release.verified_names).filter(
						(i) => Object.keys($data.release.verified_names[i]).length >= 2
					).length
				)
					return replaceRoute(routes[route]);
			} else if (route === 'existing_names') {
				if ($data.release?.existing_names && !Object.keys($data.release.existing_names).length)
					return replaceRoute(routes[route]);
			} else if (route === 'existing_usernames') {
				if (
					$data.release?.existing_usernames &&
					!Object.keys($data.release.existing_usernames).length
				)
					return replaceRoute(routes[route]);
			} else if (!$data.release[route].length) {
				return replaceRoute(routes[route]);
			}
		}
		return false;
	}

	function setStartingClaimValues() {
		if (!$data.release) return;
		if (Object.keys($data.release).length === 1 && $data.release?.sub) return;
		if ($data.release.verified_names) {
			const firstValidOccurrence = Object.keys($data.release.verified_names).filter(
				(i) => Object.keys($data.release.verified_names[i]).length >= 2
			)[0];
			selectedVerifiedName = firstValidOccurrence;
		}
		if ($data.release.existing_names) {
			const firstValidOccurrence = Object.keys($data.release.existing_names)[0];
			selectedExistingName = firstValidOccurrence;
		}
		if ($data.release.existing_usernames) {
			const firstValidOccurrence = Object.keys($data.release.existing_usernames)[0];
			selectedExistingUsername = firstValidOccurrence;
		}
		selectedPreferredName = $data.release.nicknames?.[0] || null;
		selectedFirstName = $data.release.given_names?.[0] || $data.release.managed?.given_name || null;
		selectedLastName =
			$data.release.family_names?.[0] || $data.release.managed?.family_name || null;
		selectedDiscord = $data.release.discords?.[0] || null;
		selectedTwitter = $data.release.twitters?.[0] || null;
		selectedGithub = $data.release.githubs?.[0] || null;
		selectedGitlab = $data.release.gitlabs?.[0] || null;

		if (
			Array.isArray($data?.release?.names) &&
			$data?.release?.update_scope &&
			$data?.release?.previous?.name
		) {
			const previous = $data?.release?.previous?.name;
			const previousClaimExists = $data?.release?.names.includes(previous);
			selectedName = previousClaimExists ? $data?.release?.previous.name : $data.release.names?.[0];
		} else if ($data?.release?.managed?.name) {
			selectedName = $data?.release?.managed?.name;
		} else {
			selectedName = $data.release.names?.[0] || null;
		}

		if (
			Array.isArray($data?.release?.emails) &&
			$data?.release?.update_scope &&
			$data?.release?.previous?.email
		) {
			const previous = $data?.release?.previous?.email;
			const previousClaimExists = $data?.release?.emails.includes(previous);
			selectedEmail = previousClaimExists
				? $data?.release?.previous.email
				: $data.release.emails?.[0];
		} else if ($data?.release?.managed?.email) {
			selectedEmail = $data?.release?.managed?.email;
		} else {
			selectedEmail = $data.release.emails?.[0] || null;
		}

		if (
			Array.isArray($data?.release?.phones) &&
			$data?.release?.update_scope &&
			$data?.release?.previous?.phone
		) {
			const previous = $data?.release?.previous?.phone;
			const previousClaimExists = $data?.release?.phones.includes(previous);
			selectedPhone = previousClaimExists
				? $data?.release?.previous.phone
				: $data.release.phones?.[0];
		} else {
			selectedPhone = $data.release.phones?.[0] || null;
		}

		if (
			Array.isArray($data?.release?.pictures) &&
			$data?.release?.update_scope &&
			$data?.release?.previous?.picture
		) {
			//'previous' property only returns 'picture' value of picture object
			//tbd => if no pictureobj found, pick first
			const previous = $data?.release?.previous?.picture;
			const pictureObj = $data?.release?.pictures.find((i) => i.picture === previous);
			selectedPicture = pictureObj || $data.release.pictures?.[0];
		} else if ($data?.release?.managed?.picture) {
			selectedPicture = $data?.release?.managed?.picture;
		} else {
			selectedPicture = $data.release.pictures?.[0] || null;
		}

		if (
			Array.isArray($data?.release?.ethereums) &&
			$data?.release?.update_scope &&
			$data?.release?.previous
		) {
			//'previous' property only returns 'id' value of ethereum object
			const previous = $data?.release?.previous?.ethereum;
			const ethObj = $data?.release?.ethereums.find((i) => i.address === previous);
			selectedEthAddress = ethObj || $data.release.ethereums?.[0];
		} else {
			selectedEthAddress = $data.release.ethereums?.[0] || null;
		}
	}

	async function logProviderResPlausibleEvents() {
		//End of Email Upgrade Funnel
		//email upgrade funnel state is valid and not already sent + log in success
		if (
			sessionStorage.email_upgrade_funnel === 'email_upgrade_start' &&
			($data.isPersonalLoggedIn || $data.isManagedLoggedIn)
		) {
			const email_domain = $data?.preferred?.[0]?.user_name?.split('@')[1];
			logPlausibleEvent({
				n: 'Email Upgrade Success',
				p: { email_domain, slug: hashParams.get('provider') },
				u: '/'
			});
			sessionStorage.removeItem('email_upgrade_funnel');
		}

		//New User Release Funnel
		const indexOfCurrentFunnelStep = window.authorizeFunnelSteps.indexOf(
			sessionStorage.az_release_funnel
		);
		const indexOfNextFunnelStep = window.authorizeFunnelSteps.indexOf('az_login_success');
		//session funnel state is valid and not already sent + logged in + not link flow
		if (
			indexOfCurrentFunnelStep !== -1 &&
			indexOfNextFunnelStep > indexOfCurrentFunnelStep &&
			($data?.isPersonalLoggedIn || $data?.isManagedLoggedIn) &&
			$data?.notification?.type !== 'link'
		) {
			const provider = hashParams.get('provider');
			const client_id = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
				'client_id'
			);
			const redirect_uri = new URLSearchParams(sessionStorage.authorize_query_params)?.get(
				'redirect_uri'
			);
			let redirect;
			try {
				redirect = new URL(redirect_uri)?.hostname;
			} catch (err) {
				// console.error(err)
			}
			try {
				$showSpinner = true;
				//we are await-ing because response can be a redirect back to app - we dont want to lose capturing events
				if (!$data?.uri && !$data?.response_mode && $data?.isNewUser && !$data?.release?.previous) {
					//existing user: not auto-flow + isNewUser + not rerelease
					await logPlausibleEvent({
						n: 'AZ Login Success',
						p: { client_id, provider, redirect },
						u: '/'
					});
					sessionStorage.setItem('az_release_funnel', 'az_login_success');
				} else {
					await logPlausibleEvent({
						n: 'AZ Existing User',
						p: { client_id, provider, redirect },
						u: '/'
					});
					sessionStorage.removeItem('az_release_funnel');
				}
			} catch (err) {
				console.error(err);
			}
		}
	}

	function handleDropdown(toggledDropdown) {
		for (const i in dropdownStates) {
			if (i === toggledDropdown) {
				dropdownStates[i] = !dropdownStates[i];
				continue;
			}
			dropdownStates[i] = false;
		}
		phoneOTPState = emailOTPState = false;
	}

	async function submit() {
		const body = {};

		if ($data.release?.managed) {
			body.managed = {};
		}

		if (selectedVerifiedName && $data.release?.verified_names) {
			body.verified_name = selectedVerifiedName;
		}
		if (selectedExistingName && $data.release?.existing_names) {
			body.existing_name = selectedExistingName;
		}
		if (selectedExistingUsername && $data.release?.existing_usernames) {
			body.existing_username = selectedExistingUsername;
		}
		if (selectedName) {
			if ($data.release?.managed?.name) {
				body.managed.name = selectedName;
			} else if ($data.release?.names) {
				body.name = {
					value: selectedName,
					ordinal: $data.release.names.indexOf(selectedName)
				};
			}
		}
		if (selectedPreferredName && $data.release?.nicknames) {
			body.nickname = {
				value: selectedPreferredName,
				ordinal: $data.release.nicknames.indexOf(selectedPreferredName)
			};
		}
		if (selectedFirstName) {
			if ($data.release?.managed?.given_name) {
				body.managed.given_name = selectedFirstName;
			} else if ($data.release?.given_names) {
				body.given_name = {
					value: selectedFirstName,
					ordinal: $data.release.given_names.indexOf(selectedFirstName)
				};
			}
		}
		if (selectedLastName) {
			if ($data.release?.managed?.family_name) {
				body.managed.family_name = selectedLastName;
			} else if ($data.release?.family_names) {
				body.family_name = {
					value: selectedLastName,
					ordinal: $data.release.family_names.indexOf(selectedLastName)
				};
			}
		}
		if (selectedEmail) {
			if ($data.release?.managed?.email) {
				body.managed.email = selectedEmail;
			} else if ($data.release?.emails) {
				body.email = {
					value: selectedEmail,
					ordinal: $data.release.emails.indexOf(selectedEmail)
				};
			}
		}
		if (selectedPhone && $data.release?.phones) {
			body.phone = {
				value: selectedPhone,
				ordinal: $data.release.phones.indexOf(selectedPhone)
			};
		}
		if (selectedEthAddress && $data.release?.ethereums) {
			const ethObj = {
				value: selectedEthAddress.address,
				ordinal: $data.release?.ethereums.findIndex((i) => i.address === selectedEthAddress.address)
			};
			body.ethereum = ethObj;
		}
		if (selectedPicture) {
			if ($data.release?.managed?.picture) {
				body.managed.picture = selectedPicture;
			} else if ($data.release?.pictures) {
				body.picture = {
					value: selectedPicture.picture,
					ordinal: $data.release.pictures.findIndex((i) => i.picture === selectedPicture.picture)
				};
			}
		}
		if (selectedDiscord?.id && $data.release?.discords) {
			body.discord = {
				value: selectedDiscord, //{id, username}
				ordinal: $data.release.discords.findIndex((i) => i.id === selectedDiscord.id)
			};
		}
		if (selectedTwitter?.id && $data.release?.twitters) {
			body.twitter = {
				value: selectedTwitter, //{id, username}
				ordinal: $data.release.twitters.findIndex((i) => i.id === selectedTwitter.id)
			};
		}
		if (selectedGithub?.id && $data.release?.githubs) {
			body.github = {
				value: selectedGithub, //{id, username}
				ordinal: $data.release.githubs.findIndex((i) => i.id === selectedGithub.id)
			};
		}
		if (selectedGitlab?.id && $data.release?.gitlabs) {
			body.gitlab = {
				value: selectedGitlab, //{id, username}
				ordinal: $data.release.gitlabs.findIndex((i) => i.id === selectedGitlab.id)
			};
		}
		if ($data.release?.console) body.console = true;
		if ($data.release?.quickstart) body.quickstart = true;
		if ($data.release?.recovery) body.recovery = true;
		submitFormRequest = true;
		try {
			const res = await postConsent(body);

			//End of User Release Funnel
			const indexOfCurrentFunnelStep = window.authorizeFunnelSteps.indexOf(
				sessionStorage.az_release_funnel
			);
			const indexOfNextFunnelStep = window.authorizeFunnelSteps.indexOf('az_release');
			//session funnel state is valid and not already sent + new user (isNewUser flag + not rerelease (`previous` flag in claim object))
			if (
				indexOfCurrentFunnelStep !== -1 &&
				indexOfNextFunnelStep > indexOfCurrentFunnelStep &&
				$data?.isNewUser &&
				!$data?.release?.previous
			) {
				await logPlausibleEvent({ n: 'AZ Release', u: '/' });
				sessionStorage.removeItem('az_release_funnel');
			}

			if ($isRemoteAuthClient) {
				remoteAuthDone = true;
				notification.show('Authorization is complete', 'success');
				evtSource.close();
			} else {
				handleConsentResponse(res);
				return;
			}
		} catch (err) {
			//Handle NOT_USER_SUBJECT error
			if (err.status === 400) {
				err
					.json()
					.then((res) => {
						console.info(res);
						notification.show($_('Something went wrong'), 'error');
					})
					.catch((err) => console.error(err));
			} else {
				console.error(err);
			}
			submitFormRequest = false;
			// loadingFailed = true;
		}
	}

	async function continueWithProvider(slug, attribute, server) {
		try {
			$showSpinner = true;
			const { redirect } = await postLinkProvider({
				slug,
				attribute,
				server
			});
			window.location.href = redirect;
		} catch (err) {
			$showSpinner = false;
			console.error(err);
		}
	}

	async function cancelConsent() {
		sessionStorage.removeItem('az_release_funnel');
		if ($isRemoteAuthClient) {
			try {
				await fetch('/api/v1/consent', { method: 'DELETE' });
				notification.show('Authorization was cancelled', 'error');
				authCancelledAtRemoteClient = true;
				if (evtSource) {
					evtSource.close();
				}
			} catch (err) {
				console.error(err);
			}
		} else {
			deleteConsent();
		}
	}

	function getHostName(uri) {
		if (!uri) return false;
		try {
			const hostname = new URL(uri)?.hostname;
			return hostname;
		} catch (err) {
			console.error(err);
			return false;
		}
	}

	function returnToApp() {
		let params = errData?.params || errData;
		if (errData?.error?.code && errData.error.code >= 500) {
			params = {
				error: 'server_error',
				error_description: '',
				error_uri: ''
			};
		} else if (errData?.error?.message) {
			params = {
				error: errData.error.message?.toLowerCase(),
				error_description: '',
				error_uri: ''
			};
		}
		const searchParams = new URLSearchParams(sessionStorage.authorize_query_params);
		const errResponseObject = {
			uri: searchParams.get('redirect_uri'),
			response_type: searchParams.get('response_type'),
			response_mode: searchParams.get('response_mode'),
			params
		};
		return handleConsentResponse(errResponseObject);
	}

	function getAppNameFromSessionStorage() {
		if (!sessionStorage.app) return;
		try {
			const appName = JSON.parse(sessionStorage.app)?.name;
			return appName;
		} catch (err) {
			console.error(err);
			sessionStorage.removeItem('app');
			return false;
		}
	}

	$: isConsoleOrQuickstartApp = $data?.release?.console || $data?.release?.quickstart;
	$: hasSingleClaimRequested = $data?.release && Object.keys($data?.release)?.length === 1;
	$: isNotHelloApp = !helloAppIds.includes($data?.app?.client_id);
</script>

<AuthorizeLayout
	heading={$_('Requires your')}
	showHeading={$data?.release && Object.keys($data.release).length}
	showTitleBar={!authCancelledAtRemoteClient &&
		!authCancelledAtInitClient &&
		!remoteAuthDone &&
		Object.keys($data).length &&
		!$data?.uri}
	showDeviceInfo={$isRemoteAuthClient &&
		!authCancelledAtRemoteClient &&
		!authCancelledAtInitClient &&
		!remoteAuthDone}
	closePageState={authCancelledAtInitClient ||
		authCancelledAtRemoteClient ||
		remoteAuthDone ||
		invalidQr}
	showCancelConsent={false}
>
	<section class="max-w-md mx-auto">
		<div class="space-y-2 relative">
			<!-- Hide API Access section for first party apps unless console is the only requested scope -->
			{#if isConsoleOrQuickstartApp && (hasSingleClaimRequested || isNotHelloApp)}
				<APIAccessTable />
			{/if}

			{#if selectedName}
				<FullNameSelect
					bind:selected={selectedName}
					bind:expanded={dropdownStates.nameDropdown}
					{handleDropdown}
				/>
			{/if}

			{#if selectedFirstName}
				<FirstNameSelect
					bind:selected={selectedFirstName}
					bind:expanded={dropdownStates.firstNameDropdown}
					{handleDropdown}
				/>
			{/if}

			{#if selectedLastName}
				<LastNameSelect
					bind:selected={selectedLastName}
					bind:expanded={dropdownStates.lastNameDropdown}
					{handleDropdown}
				/>
			{/if}

			{#if selectedEmail}
				<EmailSelect
					bind:selected={selectedEmail}
					bind:expanded={dropdownStates.emailDropdown}
					bind:emailOTPState
					{handleDropdown}
					{continueWithProvider}
				/>
			{/if}

			{#if selectedPicture}
				<PictureSelect
					bind:selected={selectedPicture}
					bind:expanded={dropdownStates.pictureDropdown}
					{handleDropdown}
					{continueWithProvider}
				/>
			{/if}

			{#if $data?.release?.managed?._meta}
				<OrgInfo />
			{/if}

			{#if selectedPhone}
				<PhoneSelect
					bind:selected={selectedPhone}
					bind:expanded={dropdownStates.phoneDropdown}
					bind:phoneOTPState
					{handleDropdown}
					{continueWithProvider}
				/>
			{/if}

			{#if selectedPreferredName}
				<PreferredNameSelect
					bind:selected={selectedPreferredName}
					bind:expanded={dropdownStates.preferredNameDropdown}
					{handleDropdown}
				/>
			{/if}

			{#if selectedEthAddress}
				<EthereumSelect
					bind:selected={selectedEthAddress}
					bind:expanded={dropdownStates.ethAddressDropdown}
					{handleDropdown}
				/>
			{/if}

			{#if selectedVerifiedName}
				<VerifiedNameSelect
					bind:selected={selectedVerifiedName}
					bind:expanded={dropdownStates.verifiedNameAccountsDropdown}
					{handleDropdown}
					{continueWithProvider}
				/>
			{/if}

			{#if selectedExistingName && !selectedVerifiedName}
				<ExistingNameSelect
					bind:selected={selectedExistingName}
					bind:expanded={dropdownStates.existingNameAccountsDropdown}
					{handleDropdown}
					{continueWithProvider}
				/>
			{/if}

			{#if selectedExistingUsername}
				<ExistingUsername
					bind:selected={selectedExistingUsername}
					bind:expanded={dropdownStates.existingUsernameAccountsDropdown}
					{handleDropdown}
					{continueWithProvider}
				/>
			{/if}

			{#if selectedDiscord}
				<UsernameSelect
					provider="discord"
					label={$_('Discord Username')}
					selected={selectedDiscord}
					bind:expanded={dropdownStates.discordAccountsDropdown}
					expandedKey="discordAccountsDropdown"
					{handleDropdown}
					{continueWithProvider}
				/>
			{/if}

			{#if selectedTwitter}
				<UsernameSelect
					provider="twitter"
					label={$_('X (Twitter) Username')}
					selected={selectedTwitter}
					bind:expanded={dropdownStates.twitterAccountsDropdown}
					expandedKey="twitterAccountsDropdown"
					{handleDropdown}
					{continueWithProvider}
				/>
			{/if}

			{#if selectedGithub}
				<UsernameSelect
					provider="github"
					label={$_('GitHub Username')}
					selected={selectedGithub}
					bind:expanded={dropdownStates.githubAccountsDropdown}
					expandedKey="githubAccountsDropdown"
					{handleDropdown}
					{continueWithProvider}
				/>
			{/if}

			{#if selectedGitlab}
				<UsernameSelect
					provider="gitlab"
					label={$_('GitLab Username')}
					selected={selectedGitlab}
					bind:expanded={dropdownStates.gitlabAccountsDropdown}
					expandedKey="gitlabAccountsDropdown"
					{handleDropdown}
					{continueWithProvider}
				/>
			{/if}
		</div>

		{#if $data?.app}
			<AppReleaseInfo app={$data.app} />
		{/if}

		<div class="flex flex-col-reverse unstack:flex-row justify-between gap-y-4">
			<button
				data-test="cancel-btn"
				on:click|preventDefault={cancelConsent}
				class="btn-border unstack:w-36">{$_('Cancel')}</button
			>
			<!-- svelte-ignore a11y-autofocus -->
			<button
				autofocus={window && window.innerHeight > 1000}
				data-test="continue-btn"
				class="btn-background unstack:w-36 disabled:opacity-50 disabled:cursor-not-allowed"
				on:click={submit}
				disabled={submitFormRequest}
			>
				{#if submitFormRequest}
					<SpinnerIcon css="h-5 w-5 mx-auto text-white" />
				{:else}
					{$_('Continue')}
				{/if}
			</button>
		</div>
	</section>
</AuthorizeLayout>

{#if loadingFailed}
	<div class="text-center p-10">
		{#if noSession}
			No Session - <a href={window.location.origin} class="hover:underline focus:underline"
				>Go to Hellō Wallet</a
			>
		{:else}
			{@const searchParams = new URLSearchParams(sessionStorage.authorize_query_params)}
			{@const appName = getAppNameFromSessionStorage()}
			{@const clientId = searchParams.get('client_id')}
			{@const redirect = searchParams.get('redirect_uri')}
			{@const hostName = getHostName(redirect)}
			{@const validResponseType = ['code', 'id_token'].includes(searchParams.get('response_type'))}
			{@const walletUrl = new URL('https://wallet.hello-dev.net')}
			{@const walletHostnameParts = walletUrl.hostname.split('.')}
			{@const walletSubdomain = walletHostnameParts.slice(-2).join('.')}
			{@const console =
				`https://console.${walletSubdomain}/` + (clientId ? '?client_id=' + clientId : '')}

			{#if unsupportedRedirectUri}
				<div class="mb-12 flex flex-col items-center">
					{#if clientId}
						<span
							class="px-6 py-2 bg-red-500 rounded-md flex items-center space-x-2 text-white dark:text-[#d4d4d4]"
							data-test="error-notification"
						>
							<span>The following redirect_uri is not configured for client_id {clientId}</span>
							<Copy content={clientId} />
						</span>
					{/if}
					{#if redirect}
						<CopyLink link={redirect} dataTest="error-redirect-uri" />
					{/if}
					<p class="mt-6">{$_('Please report this to the app developer')}</p>
				</div>
			{:else if unconfiguredRedirectUri}
				<div class="mb-12 flex flex-col items-center">
					{#if clientId}
						<span
							class="px-6 py-2 bg-red-500 rounded-md flex items-center space-x-2 text-white dark:text-[#d4d4d4]"
							data-test="error-notification"
						>
							<span>The following redirect_uri is not configured for client_id {clientId}</span>
							<Copy content={clientId} />
						</span>
					{/if}
					{#if redirect}
						<CopyLink link={redirect} dataTest="error-redirect-uri" />
					{/if}
					<p class="mt-6">To resolve, add it as a redirect_uri in the</p>
					{#if console}
						<a
							href={console}
							target="_blank"
							data-test="redirect-fix-dev-console-link"
							class="underline mt-2 flex items-center space-x-1"
						>
							<span>Hellō Developer Console</span>
							<ExternalLinkIcon css="h-4 mt-0.5" />
						</a>
					{/if}
				</div>
			{:else if accessDenied}
				<div class="mb-12 flex flex-col items-center">
					{#if clientId}
						<span
							class="px-6 py-2 bg-red-500 rounded-md text-white dark:text-[#d4d4d4]"
							data-test="error-notification"
							>You do not have access to the following redirect_uri</span
						>
					{/if}
					{#if redirect}
						<CopyLink link={redirect} dataTest="error-redirect-uri" />
					{/if}
				</div>
			{/if}

			{#if hostName && validResponseType}
				<button
					on:click={returnToApp}
					class="hover:underline focus:underline"
					data-test="return-uri-text">Return to {appName || hostName}</button
				>
			{:else}
				<button
					on:click={() => history.back()}
					data-test="return-uri-text"
					class="hover:underline focus:underline">Go back</button
				>
			{/if}
		{/if}
	</div>
{/if}
