<script>
	import { onMount } from 'svelte';
	import { _ } from 'svelte-i18n';
	import { handleConsentResponse } from '$utils/consent.js';
	// import {
	// 	promptForPasskey,
	// } from '$utils/passkey.js';
	import { logPlausibleEvent } from '$utils/plausible.js';
	import { showSpinner, data, notification } from '$src/stores.js';
	import { getCreateChallenge, postNoPromptPasskey, postCreateChallenge } from '$utils/api.js';
	import { startRegistration } from '@simplewebauthn/browser';
	import { getConsent } from '$utils/api.js';
	import { push as navigateTo, replace as replaceRoute } from 'svelte-spa-router';
	import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte';
	import AuthorizeLayout from '$lib/layout/AuthorizeLayout.svelte';
	import PasskeyIcon from '$lib/icon/PasskeyIcon.svelte';

	onMount(async () => {
		$showSpinner = true;

		//we dont have consent data
		if (!$data?.version) {
			try {
				$data = await getConsent();
			} catch {
				return replaceRoute('/login');
			}
		}

		if (!$data?.isPersonalLoggedIn && !$data?.isManagedLoggedIn) return replaceRoute('/login');

		//uncomment to start prompting for passkey
		// const showPromptForPasskey = await promptForPasskey($data);
		// if (!showPromptForPasskey) {
		// 	return replaceRoute('/');
		// }

		logPlausibleEvent({ u: '/passkey' });

		$showSpinner = false;
	});

	let passkeyAjax = false;
	async function createPasskey() {
		try {
			passkeyAjax = true;
			const createCredentialOpts = await getCreateChallenge();
			const createCredentialRes = await startRegistration(createCredentialOpts);
			const postCredentialRes = await postCreateChallenge(createCredentialRes);
			if (!postCredentialRes?.verified) throw postCredentialRes;
			$data = await getConsent();
			setTimeout(() => {
				//so it animates in
				notification.show('Hellō Passkey created on this device', 'success');
			}, 150);
			if ($data.uri && $data.response_mode) {
				//if (consentResponse())
				handleConsentResponse($data);
			} else {
				return navigateTo('/');
			}
		} catch (err) {
			console.error(err);
			// TODO: each await block try catch
			// Improve error notification here
			notification.show('Authentication cancelled', 'error');
			passkeyAjax = false;
		}
	}

	async function noPromptPasskey() {
		try {
			await postNoPromptPasskey();
			$data = await getConsent();
			if ($data.uri && $data.response_mode) {
				handleConsentResponse($data);
			} else {
				return navigateTo('/');
			}
		} catch (err) {
			console.error(err);
		}
	}

	function remindLater() {
		sessionStorage.setItem('remindPasskeyPromptLater', true);
		if ($data.uri && $data.response_mode) {
			handleConsentResponse($data);
		} else {
			return navigateTo('/');
		}
	}
</script>

<AuthorizeLayout showAppInfo={false} showCancelConsent={false} unstack={false}>
	<PasskeyIcon css="mx-auto h-9" />

	<h1 class="text-center flex-1 border-none text-lg font-medium my-2">
		{$_('Use a passkey for faster, safer log in')}
	</h1>
	<p class="text-sm text-center">{$_('Log in using your face, fingerprint, or device PIN')}</p>

	<div class="space-y-3 my-6 mx-auto max-w-xs text-sm">
		<button
			on:click={createPasskey}
			disabled={passkeyAjax}
			class="btn-background w-full h-10 flex items-center justify-center"
		>
			{#if passkeyAjax}
				<SpinnerIcon css="h-5 w-5 block mx-auto" />
			{:else}
				<PasskeyIcon css="h-4 w-4" />
				<span class="ml-2">{$_('Create Hellō Passkey')}</span>
			{/if}
		</button>
		<button on:click={remindLater} class="btn-border w-full h-10">{$_('Remind me later')}</button>
	</div>
	<button on:click={noPromptPasskey} class="hover:underline focus:underline text-sm block mx-auto"
		>{$_("Don't ask again on this device")}</button
	>
</AuthorizeLayout>
