<script>
	import { _ } from 'svelte-i18n';
	import { push as navigateTo, replace as replaceRoute } from 'svelte-spa-router';
	import Modal from '$lib/modal/Modal.svelte';
	import VerifyOTP from '$lib/VerifyOTP.svelte';
	import { onDestroy, onMount, tick } from 'svelte';
	import intlTelInput from 'intl-tel-input';
	import { data, showSpinner, notification, isRemoteAuthClient } from '$src/stores.js';
	import 'intl-tel-input/build/css/intlTelInput.css';
	import logins from '$svr/providers/logins.json';
	import LoginProvider from '$lib/LoginProvider.svelte';
	import { logPlausibleEvent } from '$utils/plausible.js';
	import { IS_PROD } from '$src/constants.js';
	import {
		getConsent,
		deleteConsent,
		postLinkPhone,
		postVerifyPhone,
		postLinkPhoneCode,
		postVerifyPhoneCode,
		postLinkProvider
	} from '$utils/api.js';
	import AuthorizeLayout from '$lib/layout/AuthorizeLayout.svelte';
	import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte';

	let showOTPModal = false;
	let buttonDisabled = true;
	let ajaxRequest = false;
	let evtSource;

	let ajaxRequestSendOTP = false;
	let ajaxRequestResendOTP = false;

	let authCancelledAtRemoteClient = false;
	let authCancelledAtInitClient = false;

	let iti, ref;
	let otp = '';

	onMount(async () => {
		$showSpinner = true;

		//we dont have consent data
		if (!$data?.version) {
			try {
				$data = await getConsent();
			} catch {
				return replaceRoute('/login');
			}
		}

		if (!$data?.isPersonalLoggedIn && !$data?.isManagedLoggedIn) return replaceRoute('/login');

		//we already got a phone
		if ($data.release?.phones?.length) return replaceRoute('/');

		if ($isRemoteAuthClient) {
			evtSource = new EventSource('/api/v1/login/qrcode/status');
			evtSource.addEventListener('cancel', () => {
				notification.show('Authorization was cancelled on the other device', 'error');
				authCancelledAtInitClient = true;
				evtSource.close();
			});
			evtSource.addEventListener('keep-alive', (event) => {
				if (!IS_PROD) {
					console.log('keep-alive: ' + event.data);
				}
			});
		}

		logPlausibleEvent({ u: '/wizard/phone' });

		//we hide spinner here because we want the telephone input to present in DOM for the intlTelInput to find
		$showSpinner = false;

		await tick(); //wait for tel html input to render
		ref = document.getElementById('tel');
		iti = intlTelInput(ref, {
			autoHideDialCode: false,
			utilsScript: window.intlTelInputUtils,
			separateDialCode: true
		});

		if (ref) {
			ref.focus();
		}

		//populate email input field with unverified phone
		if ($data.release?.unverified_phones?.length) {
			iti.setNumber($data.release.unverified_phones[0]);
			buttonDisabled = false;
		}
	});

	onDestroy(() => {
		if (evtSource) {
			evtSource.close();
		}
		if (iti) {
			iti.destroy();
		}
	});

	async function verifyPhone(resend = false) {
		try {
			ajaxRequest = true;
			// Check if user is trying to add unverified phone
			if (
				$data.release?.unverified_phones?.length &&
				$data.release?.unverified_phones.includes(iti.getNumber())
			) {
				resend
					? await postVerifyPhone(iti.getNumber(), true)
					: await postVerifyPhone(iti.getNumber());
			} else {
				resend ? await postLinkPhone(iti.getNumber(), true) : await postLinkPhone(iti.getNumber());
			}
			showOTPModal = true;
		} catch (err) {
			if (err.message === 'PHONE_ALREADY_VERIFIED') {
				notification.show(
					$_('{contact} has already been verified', {
						values: {
							contact: iti.getNumber()
						}
					}),
					'error'
				);
			} else {
				console.error(err);
			}
		} finally {
			ajaxRequest = ajaxRequestResendOTP = false;
		}
	}

	async function verifyPhoneCode(code) {
		try {
			ajaxRequestSendOTP = true;
			//Check if user is trying to add unverified phone
			if (
				$data.release?.unverified_phones?.length &&
				$data.release?.unverified_phones.includes(iti.getNumber())
			) {
				await postVerifyPhoneCode(code);
			} else {
				await postLinkPhoneCode(code, false);
			}
			$data = await getConsent();
			notification.clear();
			if ($data?.merge) {
				showOTPModal = false;
			} else {
				iti.destroy();
				navigateTo('/');
			}
		} catch (err) {
			console.error(err);
			otp = '';
		} finally {
			ajaxRequestSendOTP = false;
		}
	}

	async function continueWithProvider(slug, server) {
		try {
			$showSpinner = true;
			const { redirect } = await postLinkProvider({ slug, attribute: 'phone', server });
			window.location.href = redirect;
		} catch (err) {
			$showSpinner = false;
			console.error(err);
		}
	}

	$: {
		if (!$data?.isPersonalLoggedIn && !$data?.isManagedLoggedIn) {
			if ($data.release?.phones?.length) replaceRoute('/');
		}
	}

	async function cancelConsent() {
		sessionStorage.removeItem('az_release_funnel');
		if ($isRemoteAuthClient) {
			try {
				await fetch('/api/v1/consent', { method: 'DELETE' });
				notification.show('Authorization was cancelled', 'error');
				authCancelledAtRemoteClient = true;
				if (evtSource) {
					evtSource.close();
				}
			} catch (err) {
				console.error(err);
			}
		} else {
			deleteConsent();
		}
	}
</script>

<AuthorizeLayout
	heading={$_('Requires your phone')}
	showTitleBar={!authCancelledAtRemoteClient && !authCancelledAtInitClient}
	showDeviceInfo={$isRemoteAuthClient && !authCancelledAtRemoteClient && !authCancelledAtInitClient}
	closePageState={authCancelledAtRemoteClient || authCancelledAtInitClient}
	{cancelConsent}
>
	<form class="md:max-w-md mx-auto mt-2" on:submit|preventDefault={() => verifyPhone(false)}>
		<div class="relative">
			<!-- svelte-ignore a11y-autofocus -->
			<input
				type="tel"
				name="tel"
				class="h-12 w-full px-4"
				required
				id="tel"
				autofocus
				autocomplete="tel"
				on:input={() => {
					buttonDisabled = !iti.isValidNumber();
				}}
				placeholder={$_('enter your phone')}
			/>

			{#if showOTPModal}
				<Modal position="center" on:close={() => (showOTPModal = false)}>
					<VerifyOTP
						bind:otp
						bind:ajaxRequestSendOTP
						bind:ajaxRequestResendOTP
						verifyCode={verifyPhoneCode}
						on:resend={() => verifyPhone(true)}
					/>
				</Modal>
			{/if}
		</div>

		<button
			data-test="send-verification-code-btn"
			type="submit"
			disabled={buttonDisabled || ajaxRequest}
			class="mt-2 disabled:opacity-50 transition btn-background w-full inline-flex items-center justify-center"
		>
			{#if ajaxRequest}
				<SpinnerIcon css="h-5 w-5 text-white" />
			{:else}
				{$_('Send verification code')}
			{/if}
		</button>
	</form>

	{#if logins.filter((i) => i.claims.verified_phone).length}
		<section class="space-y-2 md:max-w-md container mx-auto">
			{#each logins.filter((i) => i.claims.verified_phone) as provider}
				<div>
					<LoginProvider
						on:click={(e) => continueWithProvider(provider.slug, e.detail)}
						{provider}
						prefix={$_('Get phone from {provider}')}
					/>
				</div>
			{/each}
		</section>
	{/if}
</AuthorizeLayout>
