<script>
	import Copy from './Copy.svelte';

	export let link;
	export let dataTest = '';
</script>

<span class="mt-6 bg-[#DCDCDC] dark:bg-[#505050] py-2 px-2 rounded-md flex items-center space-x-2">
	<span data-test={dataTest}>{link}</span>
	<Copy content={link} />
</span>
