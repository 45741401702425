<script>
	import { _ } from 'svelte-i18n';
	import { data } from '$src/stores.js';
	import { DEFAULT_MANAGED_LOGO } from '$src/constants.js';
</script>

<div data-test="org-info-container" class="space-y-1">
	<label for="org-info" class="text-sm font-medium">
		{$_('Organization')}
	</label>
	<div
		class="px-4 overflow-hidden block h-12 flex items-center rounded-md bg-charcoal bg-opacity-10 dark:bg-opacity-100"
	>
		<span class="managed-rounded-square-sm">
			<img
				src={$data?.release?.managed?._meta?.logo || DEFAULT_MANAGED_LOGO}
				alt={$data?.release?.managed?._meta?.domain || 'logo'}
				class="w-4.5 max-h-[18px] object-contain"
			/>
		</span>
		<span class="ml-4">{$data?.release?.managed?._meta.domain}</span>
	</div>
</div>
