<script>
	import { _ } from 'svelte-i18n';
	import { showSpinner, data, notification } from '$src/stores.js';
	import AppInfo from '$lib/AppInfo.svelte';
	import DeviceInfo from '$lib/DeviceInfo.svelte';
	import Notification from '$lib/Notification.svelte';
	import AuthorizeTitleBar from '$lib/AuthorizeTitleBar.svelte';
	import AuthorizeFooter from '$lib/AuthorizeFooter.svelte';

	export let preHeading = ''; //TBD: Refactor
	export let heading = '';
	export let cancelConsent = () => {};

	export let showPreHeading = false; //TBD: Refactor
	export let showHeading = true;
	export let showAppInfo = true;
	export let showTitleBar = true;
	export let showDeviceInfo = false;
	export let showCancelConsent = true;
	export let unstack = true;
	export let showFooter = true;

	export let closePageState = false;
</script>

{#if showTitleBar}
	<AuthorizeTitleBar />
{/if}

{#if $notification.text}
	<Notification />
{/if}

{#if !$showSpinner && Object.keys($data).length}
	{#if showDeviceInfo}
		<DeviceInfo on:click={cancelConsent} />
	{/if}

	<main
		class="{unstack
			? 'unstack:items-start unstack:flex-row stack:flex-col stack:items-center'
			: ''} py-4 stack:py-[5vh] [@media(min-height:1216px)]:!py-16 flex flex-col items-center gap-x-col"
	>
		{#if closePageState}
			{$_('You can now close this window')}
		{:else}
			{#if showAppInfo && $data.app}
				<AppInfo app={$data.app} isDeviceCodeFlow={$data.isDeviceCodeFlow} />
			{/if}

			<div class="{unstack ? 'unstack:w-1/2 stack:w-full' : ''} max-w-col w-full">
				{#if showPreHeading && preHeading?.length}
					<h1
						data-test="page-pre-heading"
						class="relative text-xl font-medium stack:text-center mb-4 stack:mb-8"
					>
						{@html preHeading}
					</h1>
				{/if}
				{#if showHeading && heading?.length}
					<h1 data-test="page-heading" class="relative font-medium">
						{@html heading}
					</h1>
				{/if}

				<div class="px-indent" class:mt-2={showHeading}>
					<slot />

					{#if showCancelConsent}
						<div class="flex justify-start mt-4">
							<button
								data-test="cancel-btn"
								type="button"
								on:click|preventDefault={cancelConsent}
								class="btn-border w-full">{$_('Cancel')}</button
							>
						</div>
					{/if}
				</div>
			</div>
		{/if}
	</main>

	{#if !$data.isPersonalLoggedIn && !$data?.isManagedLoggedIn && !$data.preferred && !$data?.login_hint && showFooter}
		<AuthorizeFooter />
	{/if}
{/if}
