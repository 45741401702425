<script>
	import { _ } from 'svelte-i18n';
	import { push as navigateTo, replace as replaceRoute } from 'svelte-spa-router';
	import Modal from '$lib/modal/Modal.svelte';
	import VerifyOTP from '$lib/VerifyOTP.svelte';
	import { data, showSpinner, notification, isRemoteAuthClient } from '$src/stores.js';
	import { onDestroy, onMount } from 'svelte';
	import {
		getConsent,
		deleteConsent,
		postLinkEmail,
		postVerifyEmail,
		postLinkEmailCode,
		postVerifyEmailCode,
		postLinkProvider
	} from '$utils/api.js';
	import logins from '$svr/providers/logins.json';
	import LoginProvider from '$lib/LoginProvider.svelte';
	import { dedupe } from '$utils/common.js';
	import { logPlausibleEvent } from '$utils/plausible.js';
	import { isValidEmail } from '$utils/email.js';
	import AuthorizeLayout from '$lib/layout/AuthorizeLayout.svelte';
	import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte';
	import { IS_PROD } from '$src/constants.js';

	let value = '';
	let showOTPModal = false;
	let ajaxRequest = false;

	let ajaxRequestSendOTP = false;
	let ajaxRequestResendOTP = false;
	let otp = '';

	let authCancelledAtInitClient = false;
	let authCancelledAtRemoteClient = false;

	let selectedManagedEmail;

	let evtSource;

	onMount(async () => {
		$showSpinner = true;

		//we dont have consent data
		if (!$data?.version) {
			try {
				$data = await getConsent();
			} catch {
				return replaceRoute('/login');
			}
		}

		if (!$data?.isPersonalLoggedIn && !$data?.isManagedLoggedIn) return replaceRoute('/login');

		//we already got an email
		if ($data.release?.emails?.length && !$data?.verifyManagedEmail) return replaceRoute('/');

		//populate email input field with unverified email
		if ($data.release?.unverified_emails?.length) value = $data.release?.unverified_emails[0];

		if ($isRemoteAuthClient) {
			evtSource = new EventSource('/api/v1/login/qrcode/status');
			evtSource.addEventListener('cancel', () => {
				notification.show('Authorization was cancelled on the other device', 'error');
				authCancelledAtInitClient = true;
				evtSource.close();
			});
			evtSource.addEventListener('keep-alive', (event) => {
				if (!IS_PROD) {
					console.log('keep-alive: ' + event.data);
				}
			});
		}

		if ($data?.verifyManagedEmail) selectedManagedEmail = $data?.verifyManagedEmail.emails[0];

		logPlausibleEvent({ u: '/wizard/email' });

		$showSpinner = false;
	});

	onDestroy(() => {
		if (evtSource) {
			evtSource.close();
		}
	});

	async function verifyEmail(resend = false) {
		try {
			ajaxRequest = true;
			// Check if user is trying to add unverified email
			if (
				($data.release?.unverified_emails?.length &&
					$data.release?.unverified_emails.includes(value)) ||
				selectedManagedEmail
			) {
				resend
					? await postVerifyEmail(value || selectedManagedEmail, true)
					: await postVerifyEmail(value || selectedManagedEmail);
			} else {
				resend ? await postLinkEmail(value, true) : await postLinkEmail(value);
			}

			if (selectedManagedEmail) showOTPModal = selectedManagedEmail;
			else showOTPModal = true;

			if (resend) otp = '';
		} catch (err) {
			// do nothing - we are already handling error in parent layer
		} finally {
			ajaxRequest = ajaxRequestResendOTP = false;
		}
	}

	async function verifyEmailCode(code) {
		try {
			ajaxRequestSendOTP = true;
			//Check if user is trying to add unverified email
			if (
				($data.release?.unverified_emails?.length &&
					$data.release?.unverified_emails.includes(value)) ||
				selectedManagedEmail
			) {
				await postVerifyEmailCode(code);
			} else {
				await postLinkEmailCode(code, false);
			}
			notification.clear(); //remove outstanding notifications
			$data = {}; //get latest state from / root page
			navigateTo('/');
		} catch (err) {
			console.error(err);
			otp = '';
		} finally {
			ajaxRequestSendOTP = false;
		}
	}

	async function continueWithProvider(slug, server) {
		try {
			$showSpinner = true;
			const { redirect } = await postLinkProvider({
				slug,
				attribute: 'email',
				server
			});
			window.location.href = redirect;
		} catch (err) {
			$showSpinner = false;
			console.error(err);
		}
	}

	async function cancelConsent() {
		sessionStorage.removeItem('az_release_funnel');
		if ($isRemoteAuthClient) {
			try {
				await fetch('/api/v1/consent', { method: 'DELETE' });
				notification.show('Authorization was cancelled', 'error');
				authCancelledAtRemoteClient = true;
				if (evtSource) {
					evtSource.close();
				}
			} catch (err) {
				console.error(err);
			}
		} else {
			deleteConsent();
		}
	}
</script>

<AuthorizeLayout
	showHeading={!$data?.verifyManagedEmail}
	heading={$_('Requires your email')}
	showTitleBar={!authCancelledAtRemoteClient && !authCancelledAtInitClient}
	showDeviceInfo={$isRemoteAuthClient && !authCancelledAtRemoteClient && !authCancelledAtInitClient}
	closePageState={authCancelledAtRemoteClient || authCancelledAtInitClient}
	unstack={false}
	{cancelConsent}
>
	{#if $data?.verifyManagedEmail}
		{@const managed = $data?.verifyManagedEmail}
		<h1 class="text-lg">{$_('Please verify the email to be used with')}</h1>
		<h1 data-test="domain" class="text-lg font-semibold px-4">{managed.domain}</h1>

		<div class="my-6 space-y-5">
			{#if Array.isArray(managed.emails) && [...new Set(managed.emails)].length > 1}
				<div class="bg-charcoal bg-opacity-10 dark:bg-opacity-100 space-y-2 p-2 rounded-md mt-2">
					<div data-test="emails-container" class="relative flex items-center">
						<div class="w-full">
							<ul class="flex flex-col items-start space-y-2 mt-1">
								{#each dedupe(managed.emails) as email, index (email)}
									<li data-test="email-{index}" class="flex items-center w-full relative">
										<input
											id="email-{email}"
											name="email"
											type="radio"
											bind:group={selectedManagedEmail}
											checked={index === 0}
											value={email}
											class="w-4 h-4 rounded-full form-radio text-charcoal dark:text-[#808080]"
										/>
										<label
											for="email-{email}"
											class="ml-2.5 inline-flex items-center truncate w-full"
										>
											<span class="truncate">
												{email}
											</span>
										</label>

										{#if showOTPModal === email}
											<Modal
												position="center"
												class="top-8"
												on:close={() => {
													showOTPModal = false;
													otp = '';
												}}
											>
												<VerifyOTP
													bind:otp
													bind:ajaxRequestSendOTP
													bind:ajaxRequestResendOTP
													verifyCode={verifyEmailCode}
													on:resend={() => verifyEmail(true)}
												/>
											</Modal>
										{/if}
									</li>
								{/each}
							</ul>
						</div>
					</div>
				</div>
			{:else}
				<div class="relative">
					<div
						class="px-4 overflow-hidden block h-12 flex items-center rounded-md bg-charcoal bg-opacity-10 dark:bg-opacity-100"
					>
						{selectedManagedEmail}
					</div>
					{#if showOTPModal === selectedManagedEmail}
						<Modal
							position="center"
							class="top-10"
							on:close={() => {
								showOTPModal = false;
								otp = '';
							}}
						>
							<VerifyOTP
								bind:otp
								bind:ajaxRequestSendOTP
								bind:ajaxRequestResendOTP
								verifyCode={verifyEmailCode}
								on:resend={() => verifyEmail(true)}
							/>
						</Modal>
					{/if}
				</div>
			{/if}

			<button
				data-test="email-send-verification-btn"
				on:click={() => verifyEmail(false)}
				disabled={ajaxRequest}
				class="disabled:opacity-60 relative transition btn-background h-12 w-full inline-flex items-center justify-center"
			>
				{#if ajaxRequest}
					<SpinnerIcon css="h-5 w-5 text-white" />
				{:else}
					{$_('Send verification code')}
				{/if}
			</button>
		</div>
	{:else}
		<form class="max-w-md mx-auto" on:submit|preventDefault={() => verifyEmail(false)}>
			<div class="relative">
				<!-- svelte-ignore a11y-autofocus -->
				<input
					id="email"
					bind:value
					type="email"
					name="email"
					class="h-12 w-full px-4"
					required
					autofocus
					autocomplete="email"
					autocapitalize="off"
					placeholder={$_('enter your email')}
				/>

				{#if showOTPModal}
					<Modal
						position="center"
						on:close={() => {
							showOTPModal = false;
							otp = '';
						}}
					>
						<VerifyOTP
							bind:otp
							bind:ajaxRequestSendOTP
							bind:ajaxRequestResendOTP
							verifyCode={verifyEmailCode}
							on:resend={() => verifyEmail(true)}
						/>
					</Modal>
				{/if}
			</div>

			<div class="flex justify-between items-center my-2">
				<button
					data-test="send-verification-code-btn"
					type="submit"
					disabled={!isValidEmail(value) || ajaxRequest}
					class="disabled:opacity-50 transition btn-background w-full inline-flex items-center justify-center"
				>
					{#if ajaxRequest}
						<SpinnerIcon css="h-5 w-5 text-white" />
					{:else}
						{$_('Send verification code')}
					{/if}
				</button>
			</div>
		</form>

		<section class="space-y-2">
			{#each logins.filter((i) => i.claims.verified_email) as provider}
				<LoginProvider
					on:click={(e) => continueWithProvider(provider.slug, e.detail)}
					{provider}
					prefix="Get email from"
				/>
			{/each}
		</section>
	{/if}
</AuthorizeLayout>
